import * as React from "react";
const StrikeIcon = ({ isMobile = false }) => {
    if (isMobile) {
        return <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.24836 21.5C5.2205 20.2631 5.34083 18.4315 5.706 17.254C11.6758 20.1667 12.455 14.8333 12.0063 13.5C14.0133 14.8333 15.9593 19.3918 13.8919 21.5" stroke="#5E5F5E" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.86413 21.5C1.56558 20.0714 0.467209 13.5637 2.28144 10.0714C3.60906 12.9286 5.77573 12.9286 5.77573 12.9286C4.33128 7.21429 7.22018 2.92857 12.7901 1.5C11.6981 3.50133 10.8245 6.62842 13.0384 8.63203C13.507 7.74053 15.0819 5.78571 17.3313 5.78571C17.3313 5.78571 15.6019 8.85492 17.4788 11.5294C19.6451 14.6163 19.0904 19.2135 15.8685 21.5" stroke="#5E5F5E" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    }
    return <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.60448 16C3.83359 15.0723 3.92384 13.6986 4.19771 12.8155C8.67507 15 9.25945 11 8.9229 10C10.4282 11 11.8877 14.4189 10.3371 16" stroke="#A0A0A1" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.31631 16C1.0924 14.9286 0.26862 10.0478 1.62929 7.42857C2.62501 9.57143 4.25001 9.57143 4.25001 9.57143C3.16668 5.28571 5.33335 2.07143 9.51076 1C8.69182 2.501 8.0366 4.84631 9.697 6.34902C10.0485 5.68039 11.2296 4.21429 12.9167 4.21429C12.9167 4.21429 11.6196 6.51619 13.0273 8.52207C14.652 10.8372 14.236 14.2851 11.8196 16" stroke="#A0A0A1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
}

export default StrikeIcon