import React from 'react';

const Search = () => {
    return (
      <>
         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="7" stroke="#11734A" strokeWidth="2"/>
          <path d="M14.707 14.2929L18.707 18.2929" stroke="#11734A" strokeWidth="2"/>
        </svg>
     </>
 );
};

export default Search;
