import { Box, Grid } from "@mui/material";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import insightsProgressStyles from "./../insightsClasses";
import CommonTypo from "../../../components/CommonTypo";
import MoreButton from "./../MoreButton";
import NewButton from "../../../components/NewButton";
import OutcomeIcon from "../../../assets/images/icon/ProgressInsights/OutcomeIcon";
import SparkleIcon from "../../../assets/images/icon/ProgressInsights/SparkleIcon";



interface InsightsOutcomesModal {
  setSelectOutcome: (selectOutcome: string) => void;
  selectOutcome: string;
  outcomeData: any[]
  influenceResponse?: any
}

const DataEntryInfluence = (props: InsightsOutcomesModal) => {
  const classesV2 = insightsProgressStyles()
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false)
  let allOutcomes = []
  if (props?.outcomeData && props?.outcomeData.length) {
    allOutcomes = showMore ? [...props?.outcomeData] : [...props?.outcomeData].slice(0, 8)
  }
  const { data = [] } = props.influenceResponse
  const responseCount = data[0]?.responseCount
  console.log("props.selectOutcome", responseCount);

  return (
    <>
      <Box sx={{ flexGrow: 1, }}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          justifyContent: "space-between"
        }}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px"
          }}>
            {responseCount >= 21 && (
              <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "56px",
                  width: "56px",
                  borderRadius: "35px",
                  background: "var(--color-new-main)"
                }}>
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.8125 22.75C25.8125 23.0981 25.6742 23.4319 25.4281 23.6781C25.1819 23.9242 24.8481 24.0625 24.5 24.0625H3.5C3.1519 24.0625 2.81806 23.9242 2.57192 23.6781C2.32578 23.4319 2.1875 23.0981 2.1875 22.75V5.25C2.1875 4.9019 2.32578 4.56806 2.57192 4.32192C2.81806 4.07578 3.1519 3.9375 3.5 3.9375C3.8481 3.9375 4.18194 4.07578 4.42808 4.32192C4.67422 4.56806 4.8125 4.9019 4.8125 5.25V14.607L9.63594 10.3906C9.86238 10.1925 10.15 10.0782 10.4507 10.0669C10.7513 10.0555 11.0468 10.1478 11.2875 10.3283L17.4366 14.9395L23.6359 9.51562C23.7629 9.38886 23.9146 9.28949 24.0815 9.22364C24.2484 9.1578 24.4271 9.12688 24.6064 9.13279C24.7857 9.13871 24.962 9.18133 25.1242 9.25803C25.2864 9.33474 25.4312 9.44389 25.5495 9.57874C25.6679 9.71359 25.7574 9.87129 25.8124 10.0421C25.8674 10.2129 25.8868 10.3931 25.8695 10.5717C25.8521 10.7503 25.7983 10.9234 25.7113 11.0804C25.6244 11.2374 25.5062 11.3749 25.3641 11.4844L18.3641 17.6094C18.1376 17.8075 17.85 17.9218 17.5493 17.9331C17.2487 17.9445 16.9532 17.8522 16.7125 17.6717L10.5634 13.0638L4.8125 18.0961V21.4375H24.5C24.8481 21.4375 25.1819 21.5758 25.4281 21.8219C25.6742 22.0681 25.8125 22.4019 25.8125 22.75Z" fill="#3E4240" />
                  </svg>

                </Box>
              </Box>
            )}


            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              gap: "8px"
            }}>
              <CommonTypo size={24} color="#3E4240" fontWeight={700} extraSx={{
                whiteSpace: "nowrap",
              }}>{responseCount < 7
                ? "There’s no enough data yet..."
                : responseCount >= 21 ? "Data is now fully accurate" : "Data could be more accurate"}</CommonTypo>
              <CommonTypo size={16} color="#3E4240" fontWeight={400} extraSx={{
                whiteSpace: "nowrap",
              }}>{responseCount < 7
                ? "Please continue tracking and entering your activities for more accurate results and insights"
                : responseCount >= 21 ? "Keep tracking for ongoing insights" : "Unfold the progress bar to get more details"}</CommonTypo>
            </Box>
          </Box>
          <NewButton buttonText="Make an entry" padding="8px 15px" onClick={() => {
            navigate("/tracking")
          }} />
        </Box>
        <Box style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginTop: "8px"
        }}>
          <EntryCard title={"1"} showMore={showMore} completed={responseCount > 0} nextCompleted={true} cardComponent={<GenerateCard
            completed={responseCount > 0}
            title="First week"
            description="Start logging data to unlock outcomes insights"
          />} />
          <EntryCard title={"2"} showMore={showMore} completed={responseCount >= 7} nextCompleted={true} cardComponent={<GenerateCard
            title="Second week"
            completed={responseCount >= 7}
            description="Outcomes unlocked! Keep tracking for better accuracy"
            showLock
            icon={<OutcomeIcon size={28} color="#656565" />}
          />} />
          <EntryCard title={"3"} showMore={showMore} completed={responseCount >= 14} nextCompleted={true} cardComponent={<GenerateCard
            title="Third week"
            completed={responseCount >= 14}
            description="Keep going! More data means more accurate results"
          />} />
          <EntryCard title={"4"} showMore={showMore} completed={responseCount >= 21} cardComponent={<GenerateCard
            title="Fourth week"
            completed={responseCount >= 21}
            description="Data is now fully accurate. Keep tracking for ongoing insights"
            showLock
            icon={<SparkleIcon size={28} color="#656565" />}
          />} />
        </Box>
        <MoreButton active={showMore} onClick={() => setShowMore(!showMore)} />
      </Box>
    </>
  );
};

export default DataEntryInfluence;

const EntryCard = ({
  showMore = false,
  completed = false,
  nextCompleted = false,
  title = "1",
  cardComponent = null
}) => {
  return <Box sx={{
    width: "calc(100% / 4)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "16px",
    alignItems: "center",
    justifyContent: "center",
    "::after": {
      content: "''",
      display: "block",
      width: "calc(50% - 30px)",
      height: "16px",
      backgroundColor: "#EEEEEE",
      position: "absolute",
      right: "0",
      bottom: "calc(20px - 8px)", /* Position below the button */
      borderRadius: title === "4" && "0px 8px 8px 0px" /* Position below the button */
    },
    "::before": {
      content: "''",
      display: "block",
      width: "calc(50% - 30px)",
      height: "16px",
      backgroundColor: "#EEEEEE",
      position: "absolute",
      left: "0",
      bottom: "calc(20px - 8px)",
      borderRadius: title === "1" && "8px 0px 0px 8px" /* Position below the button */
    }
  }} onClick={() => {

  }}>
    {showMore && cardComponent}
    <Box sx={{
      height: "40px",
      padding: "16px",
      "display": "flex",
      alignItems: "center",
      gap: "8px",
      width: "max-content",
      background: completed ? "var(--color-new-main)" : "#EEEEEE",
      borderRadius: "8px",
      zIndex: 1,
      "::after": {
        content: "''",
        display: "block",
        width: "calc(50% - 30px)",
        height: "16px",
        backgroundColor: completed ? "var(--color-new-main)" : "#EEEEEE",
        position: "absolute",
        right: "0",
        bottom: "calc(20px - 8px)", /* Position below the button */
        borderRadius: title === "4" ? "0px 8px 8px 0px" : completed && !nextCompleted ? "0px 8px 8px 0px" : "0px" /* Position below the button */
      },
      "::before": {
        content: "''",
        display: "block",
        width: "calc(50% - 30px)",
        height: "16px",
        backgroundColor: completed ? "var(--color-new-main)" : "#EEEEEE",
        position: "absolute",
        left: "0",
        bottom: "calc(20px - 8px)", /* Position below the button */
        borderRadius: title === "1" && "8px 0px 0px 8px" /* Position below the button */
      }
    }}>
      <CommonTypo size={16} color="#656565" fontWeight={700}>{title}</CommonTypo>
    </Box>
  </Box>
}

const GenerateCard = ({
  completed = false,
  title = "",
  description = "",
  icon = null,
  showLock = false
}) => {
  return <Box sx={{
    width: "154px",
    height: "204px",
    border: completed ? "2px solid var(--color-new-main)" : "1px solid #EEEEEE",
    padding: "16px",
    gap: "8px",
    borderRadius: "12px",
    justifyContent: "space-between",
    opacity: "0px",
    display: "flex",
    flexDirection: "column",
  }}>
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: "8px"
    }}>
      <CommonTypo size={16} lineHeight={16} color="#3E4240" fontWeight={600} extraSx={{
        whiteSpace: "nowrap",
      }}>{title}</CommonTypo>
      <CommonTypo size={12} lineHeight={12} color="#3E4240" fontWeight={400} extraSx={{
        whiteSpace: "wrap",
      }}>{description}</CommonTypo>
    </Box>
    {icon && <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

    }}>
      <Box sx={{
        width: "56px",
        height: "56px",
        gap: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "35px",
        background: completed ? "var(--color-new-main)" : "#EEEEEE"
      }}>
        {icon}
      </Box>
    </Box>}
    <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "16px",
      gap: "6px"
    }}>
      {showLock && <CommonTypo size={12} lineHeight={12} color="#3E4240" fontWeight={400} extraSx={{
        whiteSpace: "nowrap",
      }}>{!completed ? "Locked" : "Unlocked"}</CommonTypo>}
      {showLock ? !completed ? <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4.75H10.25V3.5C10.25 2.63805 9.90759 1.8114 9.2981 1.2019C8.6886 0.59241 7.86195 0.25 7 0.25C6.13805 0.25 5.3114 0.59241 4.7019 1.2019C4.09241 1.8114 3.75 2.63805 3.75 3.5V4.75H2C1.66848 4.75 1.35054 4.8817 1.11612 5.11612C0.881696 5.35054 0.75 5.66848 0.75 6V13C0.75 13.3315 0.881696 13.6495 1.11612 13.8839C1.35054 14.1183 1.66848 14.25 2 14.25H12C12.3315 14.25 12.6495 14.1183 12.8839 13.8839C13.1183 13.6495 13.25 13.3315 13.25 13V6C13.25 5.66848 13.1183 5.35054 12.8839 5.11612C12.6495 4.8817 12.3315 4.75 12 4.75ZM5.25 3.5C5.25 3.03587 5.43437 2.59075 5.76256 2.26256C6.09075 1.93437 6.53587 1.75 7 1.75C7.46413 1.75 7.90925 1.93437 8.23744 2.26256C8.56563 2.59075 8.75 3.03587 8.75 3.5V4.75H5.25V3.5ZM11.75 12.75H2.25V6.25H11.75V12.75ZM8 9.5C8 9.69778 7.94135 9.89112 7.83147 10.0556C7.72159 10.22 7.56541 10.3482 7.38268 10.4239C7.19996 10.4996 6.99889 10.5194 6.80491 10.4808C6.61093 10.4422 6.43275 10.347 6.29289 10.2071C6.15304 10.0673 6.0578 9.88907 6.01921 9.69509C5.98063 9.50111 6.00043 9.30004 6.07612 9.11732C6.15181 8.93459 6.27998 8.77841 6.44443 8.66853C6.60888 8.55865 6.80222 8.5 7 8.5C7.26522 8.5 7.51957 8.60536 7.70711 8.79289C7.89464 8.98043 8 9.23478 8 9.5Z" fill="#656565" />
      </svg>
        : <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4.75H5.25V3.5C5.25 3.03587 5.43437 2.59075 5.76256 2.26256C6.09075 1.93437 6.53587 1.75 7 1.75C7.84438 1.75 8.60313 2.35125 8.765 3.14937C8.78462 3.2459 8.82305 3.33761 8.87811 3.41928C8.93317 3.50094 9.00378 3.57097 9.0859 3.62535C9.16802 3.67973 9.26005 3.7174 9.35673 3.73621C9.45341 3.75503 9.55285 3.75462 9.64937 3.735C9.7459 3.71538 9.83761 3.67695 9.91928 3.62189C10.0009 3.56683 10.071 3.49622 10.1253 3.4141C10.1797 3.33198 10.2174 3.23995 10.2362 3.14327C10.255 3.04659 10.2546 2.94715 10.235 2.85063C9.92937 1.34375 8.56875 0.25 7 0.25C6.13835 0.250992 5.31228 0.593721 4.703 1.203C4.09372 1.81228 3.75099 2.63835 3.75 3.5V4.75H2C1.66848 4.75 1.35054 4.8817 1.11612 5.11612C0.881696 5.35054 0.75 5.66848 0.75 6V13C0.75 13.3315 0.881696 13.6495 1.11612 13.8839C1.35054 14.1183 1.66848 14.25 2 14.25H12C12.3315 14.25 12.6495 14.1183 12.8839 13.8839C13.1183 13.6495 13.25 13.3315 13.25 13V6C13.25 5.66848 13.1183 5.35054 12.8839 5.11612C12.6495 4.8817 12.3315 4.75 12 4.75ZM11.75 12.75H2.25V6.25H11.75V12.75ZM6 9.5C6 9.30222 6.05865 9.10888 6.16853 8.94443C6.27841 8.77998 6.43459 8.65181 6.61732 8.57612C6.80004 8.50043 7.00111 8.48063 7.19509 8.51921C7.38907 8.5578 7.56725 8.65304 7.70711 8.79289C7.84696 8.93275 7.9422 9.11093 7.98079 9.30491C8.01937 9.49889 7.99957 9.69996 7.92388 9.88268C7.84819 10.0654 7.72002 10.2216 7.55557 10.3315C7.39112 10.4414 7.19778 10.5 7 10.5C6.73478 10.5 6.48043 10.3946 6.29289 10.2071C6.10536 10.0196 6 9.76522 6 9.5Z" fill="#656565" />
        </svg>
        : null}
    </Box>
  </Box>
}