/*eslint eqeqeq: "off"*/
import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Checkbox, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../theme/typography";
import GoogleIcon, { NewGoogleIcon } from "../../assets/images/icon/google-icon";
import FacebookIcon, { NewFacebookIcon } from "../../assets/images/icon/facebook-icon";
import { Link, useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from "formik";
import * as yup from "yup";
import palette from "../../theme/palette";
import SnackBarComp from "../../components/snackBar";
import { userService } from "../../services/auth";
import { facebookProvider, googleProvider } from "../../config/authMethods";
import FullpageLoader from "../../components/fullPageLoader";
import { ButtonLoader } from "../../components/buttonLoader";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import {
  CreateUserModal,
  USerContinueRegister,
} from "../../interfaces/outcomeModal";
import { setUserToken } from "../../store/actions/userTypeAction";
import { UserTypeContext } from "../../context/userStatus";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import queryParams from "../../utils/getQueryParams";

const validationSchema = yup.object({
  fname: yup.string().required("First name is required"),
  lname: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  terms_condition: yup
    .boolean()
    .required('You must accept the Terms of Service to proceed')
    .oneOf([true], 'Error')
});

const RootStyle = styled("div")(({ theme }) => ({
  padding: "0 100px",
  [theme.breakpoints.up("xl")]: {
    padding: "60px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
    marginBottom: "62px!important",
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  socialLoginButton: {
    marginTop: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px"
  },
  alreadyAccountText: {
    marginTop: "36px",
    color: "#3E4240",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    textAlign: "center",

  },
  alreadyAccountTextSpan: {
    color: "#11734A",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      textDecoration: "underline"
    },
  },
  textInputMobile: {
    "& .MuiOutlinedInput-root": {
      border: "1px solid #B8E29E",
      '& .MuiInputBase-input': {
        '&::placeholder': {
          fontSize: `${pxToRem(14)} !important`,
          color: '#3E4240',
          opacity: 1, /* Firefox */
          lineHeight: '100%',
          fontWeight: 400,
        }
      },
    },

  }
}));

const ariaLabel = { "aria-label": "description" };

const RightPartRegistration = ({ isMobile = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryData = queryParams()
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [showLoaderPage, setShowLoaderPage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const { dispatch: userTypeDispatch } = useContext(UserTypeContext);

  // FIREBASE AUTHENTICATION
  const emailAuth = async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        localStorage.setItem("userDetails", JSON.stringify(result));
        return result;
      })
      .catch((err) => {
        setShowLoader(false);
        setShowSnakbar(true);
        setMsgType("error");
        if (err.code == "auth/email-already-in-use") {
          setSnakbarMsg("User with this email already exist");
        } else {
          setSnakbarMsg(err.message);
        }
        return err;
      });
  };

  // REGISTER API
  const userContinueRegister = (values: USerContinueRegister) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const userData = {
      uid: userDetails?.user?.uid ? userDetails?.user?.uid : userDetails?.uid,
      user_email: userDetails?.user?.email
        ? userDetails?.user?.email
        : userDetails?.email,
      signUpType:
        userDetails?.providerData?.[0]?.providerId == "google.com"
          ? "GMAIL"
          : userDetails?.providerData?.[0]?.providerId == "facebook.com"
            ? "FACEBOOK"
            : "OTHER",
      first_name: values.fname,
      last_name: values.lname,
      "ipAddress": localStorage.getItem("ipAddress")

    };
    userService
      .createUser(userData)
      .then((data: CreateUserModal) => {
        localStorage.setItem("userValues", JSON.stringify(data.data));
        localStorage.setItem("userDetails", data["x-access_token"]);
        userTypeDispatch(setUserToken(data["x-access_token"]));
        navigate("/user-identification" + queryData.qeryString);
      })
      .catch((error) => {
        setShowLoader(false);
        setShowLoaderPage(false);
        setMsgType("error");
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description);
      });
  };

  const firebaseLogin = async (values: USerContinueRegister) => {
    setShowLoader(true);
    try {
      const result = await emailAuth(values.email, values.password);
      if (result.user) {
        userContinueRegister(values);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      password: "",
      terms_condition: false
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setShowLoader(true);
      firebaseLogin(values);
    },
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  const handleOnClick = async (provider: any) => {
    setShowLoaderPage(true);
    try {
      const result = await userService.socialMediaAuth(provider);
      localStorage.setItem("userDetails", JSON.stringify(result));
      if (!result.uid) {
        setShowLoaderPage(false);
        throw new Error(result.message);
      } else {
        userContinueRegister(result);
      }
    } catch (error) {
      setShowLoaderPage(false);
      setMsgType("error");
      setShowSnakbar(true);
      setSnakbarMsg(error.message);
    }
  };
  let obj = {}
  if(!isMobile) {
    obj = {
      padding: "15px 100px 0"
    }
  }
  return (
    <RootStyle className="login-right-part" sx={{
      height: "95vh",
      ...obj
    }}>
      {showLoaderPage && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      {!isMobile && <Typography
        variant="h1"
        component="h1"
        className={classes.registrationHeading}
      >
        {/* Sign up and get started today! */}
        Create an account
      </Typography>}
      <Grid padding={isMobile ? "60px 0 0" : ""} className="hide-scrollbar" sx={isMobile ? {

      } : {
        maxHeight: "90%",
        overflowY: "auto"
      }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            placeholder="First name"
            variant="outlined"
            fullWidth
            name="fname"
            value={formik.values.fname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={isMobile ? classes.textInputMobile : "newInputBox"}
            error={formik.touched.fname && Boolean(formik.errors.fname)}
            helperText={formik.touched.fname && formik.errors.fname}
            inputProps={ariaLabel}
          />
          <TextField
            placeholder="Last name"
            variant="outlined"
            fullWidth
            name="lname"
            value={formik.values.lname}
            className={isMobile ? classes.textInputMobile : "newInputBox"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lname && Boolean(formik.errors.lname)}
            helperText={formik.touched.lname && formik.errors.lname}
            inputProps={ariaLabel}
          />
          <TextField
            placeholder="Email"
            variant="outlined"
            fullWidth
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={isMobile ? classes.textInputMobile : "newInputBox"}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            inputProps={ariaLabel}
          />
          <TextField
            placeholder="Password"
            variant="outlined"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            className={isMobile ? classes.textInputMobile : "newInputBox"}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            inputProps={ariaLabel}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              </InputAdornment>
            }}
          />

          {!showLoader ? (
            <Button
              type="submit"
              variant="contained"
              className={/* isMobile ? "button-after" : */ "new-button"}
              fullWidth
              sx={{ mt: "24px", mb: "0px" }}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Sign up {!isMobile && " with email"}
            </Button>
          ) : (
            <Button
              variant="contained"
              className={/* isMobile ? "button-after" : */ "new-button"}
              fullWidth
              sx={{ mt: "24px", mb: "0px" }}
            >
              <ButtonLoader />
            </Button>
          )}
          <FormControlLabel control={<Checkbox name="terms_condition" checked={formik.values.terms_condition} onChange={formik.handleChange} sx={{
            '&.Mui-checked': {
              color: "var(--color-new-main)"
            }
          }} /* color={palette.primary.newmain}  */ />} sx={{ mt: "24px", mb: "36px" }} label={<span>By creating an account you are agree to our <a href="https://www.tenacious-tracker.com/terms" target="_blank" style={{
            color: "#190D1A"
          }}>Terms & Conditions</a></span>} />
          <FormHelperText style={{ color: "red" }}>
            {formik.touched.terms_condition && formik.errors.terms_condition
              ? formik.touched.terms_condition && formik.errors.terms_condition
              : " "}
          </FormHelperText>
        </form>
        <p className={`${isMobile ? "registation_text" : "registation_text_new"}`}>
          <span>or sign up with</span>
        </p>
        <Grid className={classes.socialLoginButton}>
          <Button
            sx={{ padding: 0 }}
            onClick={() => handleOnClick(googleProvider)}
          >
            <NewGoogleIcon />
          </Button>
          <Button
            sx={{ padding: 0 }}
            onClick={() => handleOnClick(facebookProvider)}
          >
            <NewFacebookIcon />
          </Button>

        </Grid>
        <p className={classes.alreadyAccountText}>
          Already a user?{" "}
          <span
            className={classes.alreadyAccountTextSpan}
            onClick={() => navigate("/login" + queryData.qeryString)}
          >
            {isMobile ? "Log in" : "Log in"}
          </span>
        </p>
      </Grid>
    </RootStyle>
  );
};
export default RightPartRegistration;
