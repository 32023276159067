import TreeView from "@material-ui/lab/TreeView";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Radio,
  Tab,
  Tabs,
  RadioGroup,
  Typography,
  TextField, MenuItem, Popper,
  Dialog
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { FormControlLabel } from "@material-ui/core";
import clsx from "clsx";
import { xVersion } from "../../../services/config";
import { ReviewData } from "./contactsData";
import { UserTypeContext } from "../../../context/userStatus";
import palette from "../../../theme/palette";
import { pxToRem } from "../../../theme/typography";
import NewButton from "../../../components/NewButton";
import TreeItem from "@material-ui/lab/TreeItem";
import { contactsService } from "../../../services/contactsService";
import moment from "moment";
import { ReportsTypeContext } from "../../../context/userIReports";
import {
  setSelectedRow,
  setSelectedRowDetails,
} from "../../../store/actions/reportTypeAction";
import ReportDetails from "../../progressWeek/ReportDetails";
import RightIconSwitch from "../../../assets/images/icon/RightIconSwitch";
import ArrowDownGreen from "../../../assets/images/icon/ArrowDownGreen";
import ArrowDownNew, { ArrowUpNew } from "../../../assets/images/icon/ArrowDownGreenNew";
import SelecrtionDialogue from "./SelecrtionDialogue";
import Calendar from "../../../assets/images/icon/Calendar";
const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",
  padding: "0px",
  height: "18px",
  width: "18px",
  "&.Mui-checked": {
    color: "var(--color-new-main)",
    borderRadius: "4px",
  },
}));
const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "var(--color-new-main)",
    borderRadius: "4px",
  },
}));
// Custom Popper component with border radius
const CustomPaper = styled('div')(({ theme }) => ({
  borderRadius: '12px', // Customize the border radius
  zIndex: '1300'
}));

// Styled MenuItem component
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#DDDBDB !important', // Color on hover
  },
  '&.Mui-selected': {
    backgroundColor: '#D6F5C3 !important', // Color when selected
    '&:hover': {
      backgroundColor: 'lightblue', // Color when selected and hovered
    },
  },
}));
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

const SocialReportStepper = ({
  allContacts,
  allDefaultStates,
  classes,
  selectedNodes,
  handleNodeSelect,
  setVisibleAddContactModal,
  radioValue,
  setRadioValue,
  shareReport,
  setVisibleManageContactModal,
  step,
  setStep,
  handleOutInfluSelect,
  selectedOutInflu,
  allPersonalStates,
  outcomeState,
  weekCount,
  setWeekCount,
  dayToSend,
  setdayToSend,
  sendTimeHour,
  setsendTimeHour,
  sendTimeMinute,
  setsendTimeMinute,
  sendFrequency,
  setSendFrequence,
  showSuccessMessage,
  setSuccessMessage,
  editRecord,
  seelctedVal, setSelectedVal,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [expandedIds, setExpandedIds] = useState([]);
  const [handleOpenSelectionModal, setSelectionModal] = useState(false);

  const { state: IReportTypeContext, dispatch: reportTypeDispatch } =
    useContext(ReportsTypeContext);
  let userValues = JSON.parse(localStorage.getItem("userValues"));
  console.log("dayToSend", dayToSend, "seelctedVal", seelctedVal, radioValue);

  useEffect(() => {
    if (step === 3) {
      const obj = {
        from: moment()
          .subtract(1, "week")
          .startOf("week")
          .add(1, "day")
          .format("MM-DD-YYYY"),
        to: moment()
          .subtract(1, "week")
          .endOf("week")
          .add(1, "day")
          .format("MM-DD-YYYY"),
        uid: outcomeState?.userId ? outcomeState?.userId : userValues?.uid,
        outcomeIds: allDefaultStates[0].items
          .filter((it) => selectedOutInflu.includes(it._id))
          .map((it) => it.outcomeId),
        influenceIds: allDefaultStates[1].items
          .filter((it) => selectedOutInflu.includes(it._id))
          .map((it) => it.influenceId),
      };
      if (["REGULARLY_MONTH"].includes(radioValue)) {
        if (["LAST_THIRTY_DAYS"].includes(seelctedVal)) {
          obj.from = moment()
            .subtract(30, "day")
            .format("MM-DD-YYYY")
          obj.to = moment()
            .subtract(1, "day")
            .format("MM-DD-YYYY")
        }
        if (["LAST_MONTH"].includes(seelctedVal)) {
          obj.from = moment()
            .subtract(1, "month")
            .startOf('month')
            .format("MM-DD-YYYY")
          obj.to = moment()
            .subtract(1, "month")
            .endOf('month')
            .format("MM-DD-YYYY")
        }
      }
      if (["REGULARLY_WEEK"].includes(radioValue)) {
        if (["LAST_SEVEN_DAYS"].includes(seelctedVal)) {
          obj.from = moment()
            .subtract(7, "day")
            .format("MM-DD-YYYY")
          obj.to = moment()
            .subtract(1, "day")
            .format("MM-DD-YYYY")
        }
        if (["LAST_WEEK"].includes(seelctedVal)) {
          obj.from = moment()
            .subtract(1, "week")
            .startOf("week")
            .add(1, "day")
            .format("MM-DD-YYYY")
          obj.to = moment()
            .subtract(1, "week")
            .endOf("week")
            .add(1, "day")
            .format("MM-DD-YYYY")
        }
      }
      contactsService
        .viewshareReport(obj)
        .then((data) => {
          reportTypeDispatch(setSelectedRow({ ...obj, till: obj.to }));
          reportTypeDispatch(setSelectedRowDetails({ ...data, ...obj }));
          console.log("data", data);
          // setShowSnakbar(true);
          // setMsgType("success");
          // setSnakbarMsg(data?.description);
        })
        .catch((err) => {
          if (err?.description) {
            //   setShowSnakbar(true);
            //   setMsgType("error");
            //   setSnakbarMsg(err?.description);
          }
        })
        .finally(() => {
          // setShowLoadingBtn(false)
        });
    } else {
      reportTypeDispatch(setSelectedRow({}));
      reportTypeDispatch(setSelectedRowDetails({}));
    }
  }, [step, seelctedVal, radioValue]);
  useEffect(() => {
    setSelectedTab(selectedTab);
  }, [showSuccessMessage]);
  console.log(
    "showSuccessMessage",
    showSuccessMessage,
    showSuccessMessage ? "center" : "space-between",
    dayToSend,
  );
  const disabledButton =
    step === 1
      ? selectedNodes.length === 0
      : step === 2
        ? selectedOutInflu.length === 0
        : false;
  const renderTree = (nodes, key, onNodeSleect, selectedNode, isParent, showCloseIcon = false, visibleIds = [], setExpandedIds = (ids: any[]) => { }) => (
    <TreeItem
      key={nodes._id}
      nodeId={nodes._id}
      // onClick={handleExpandClick}
      style={{
        marginBottom: "16px",
      }}
      className={classes.labelClass}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",

            gap: "8px",
          }}
        >
          <CustomCheckBox
            checked={selectedNode.indexOf(nodes._id) !== -1}
            tabIndex={-1}
            disableRipple
            onClick={(event) => onNodeSleect(event, nodes)}
            disabled={isParent && (!Array.isArray(nodes[key]) || !nodes[key].length)}
          />
          <Typography
            sx={{
              color: palette.primary.light,
              fontSize: `${pxToRem(16)} !important`,
              lineHeight: "120% !important",
              // textAlign: "center !important",
              fontWeight: `400 !important`,
              "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                  fontSize: `${pxToRem(16)} !important`,
                  lineHeight: "100% !important",
                },
              },
            }}
          >
            {nodes.groupName || nodes.name}{" "}
            {nodes[key] ? "(" + nodes[key].length + ")" : null}
          </Typography>
          {nodes[key] && showCloseIcon && <Box onClick={() => {
            if (visibleIds.includes(nodes._id)) {
              const filtered = [...visibleIds].filter(it => it !== nodes._id)
              setExpandedIds(filtered)
            } else {
              const filtered = [...visibleIds]
              filtered.push(nodes._id)
              setExpandedIds(filtered)
            }
          }}>{visibleIds.includes(nodes._id) ? <ArrowDownNew /> : <ArrowUpNew />}</Box>}
        </Box>
      }
    >
      {showCloseIcon ? (
        Array.isArray(nodes[key]) && visibleIds.includes(nodes._id)
          ? nodes[key].map((node) =>
            renderTree(node, key, onNodeSleect, selectedNode, false, showCloseIcon, visibleIds, setExpandedIds)
          )
          : null
      ) : Array.isArray(nodes[key])
        ? nodes[key].map((node) =>
          renderTree(node, key, onNodeSleect, selectedNode, false, showCloseIcon, visibleIds, setExpandedIds)
        )
        : null}
      { }
    </TreeItem>
  );
  useEffect(() => {
    const arr = selectedTab === 0
      ? allDefaultStates
      : allPersonalStates
    if (step === 2) {
      console.log("arr.map(it => it._id)", arr.map(it => it._id));

      setExpandedIds(arr.map(it => it._id))
    }

  }, [step, selectedTab])
  console.log("radioValue radioValue", allDefaultStates
    , allPersonalStates);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} display={"flex"} flexDirection={"column"}>
          {step === 1 ? (
            <>
              <Typography
                sx={{
                  color: palette.common.black,
                  fontSize: `${pxToRem(16)} !important`,
                  lineHeight: "120% !important",
                  marginBottom: "28px",
                  // textAlign: "center !important",
                  fontWeight: `600 !important`,
                  "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "100% !important",
                    },
                  },
                }}
              >
                Who do you want to share with?
              </Typography>
              <Box
                sx={{
                  height: "320px",
                  overflowY: "auto",
                }}
                className="hide-scrollbar"
              >
                <TreeView
                  multiSelect
                  expanded={allContacts.map((it) => it._id)}
                  className={classes.treeClass}
                  // defaultCollapseIcon={<ExpandMoreIcon />}
                  // defaultExpandIcon={<ChevronRightIcon />}
                  selected={[]}

                >
                  {allContacts.map((node) =>
                    renderTree(
                      node,
                      "contacts",
                      (event, nodes) => handleNodeSelect(event, nodes._id),
                      selectedNodes,
                      true
                    )
                  )}
                </TreeView>
              </Box>
            </>
          ) : step === 2 ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={(e, newTab) => {
                    setSelectedTab(newTab);
                  }}
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "var(--color-new-main)"
                    }
                  }}
                  className={classes.tabs}
                  aria-label="basic tabs example"
                >
                  <Tab label="Default" className="tabLabel" />
                  <Tab label="Personal" className="tabLabel" />
                </Tabs>
              </Box>
              <Typography
                sx={{
                  color: palette.common.black,
                  fontSize: `${pxToRem(16)} !important`,
                  lineHeight: "120% !important",
                  // textAlign: "center !important",
                  fontWeight: `600 !important`,
                  "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "100% !important",
                    },
                  },
                  margin: "30px 0px 28px",
                }}
              >
                What do you want to share?
              </Typography>
              <Box
                sx={{
                  height: "255px",
                  overflowY: "auto",
                }}
                className="hide-scrollbar"
              >
                <TreeView
                  multiSelect
                  expanded={(selectedTab === 0
                    ? allDefaultStates
                    : allPersonalStates
                  ).map((it) => it._id)}
                  className={classes.treeClass}
                  // defaultCollapseIcon={<ExpandMoreIcon />}
                  // defaultExpandIcon={<ChevronRightIcon />}
                  selected={[]}
                >
                  {(selectedTab === 0
                    ? allDefaultStates
                    : allPersonalStates
                  ).map((node) =>
                    renderTree(
                      node,
                      "items",
                      (event, nodes) => {
                        handleOutInfluSelect(
                          event,
                          nodes._id,
                          selectedTab === 0 ? false : true
                        );
                      },
                      selectedOutInflu,
                      true, true, expandedIds, setExpandedIds
                    )
                  )}
                </TreeView>
              </Box>
            </>
          ) : step === 3 ? (
            <>
              <Typography variant="h6" component="h6" sx={{
                color: palette.common.black,
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "120% !important",
                // textAlign: "center !important",
                fontWeight: `600 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "100% !important",
                  },
                },
                margin: "0px",
                marginBottom: "24px !important"
              }}>
                How often do you want to share?
              </Typography>
              <Box display={"flex"} alignItems={"flex-end"} gap={"8px"}>
                <TextField
                  className={clsx(classes.raioOptionSelect, "weekSelect")}
                  sx={{
                    marginBottom: "0px !important",
                    background: "var(--color-input-background)",
                    border: "1px solid var(--color-input-border)",
                    padding: "4px",
                    width: "166px",
                    "& .MuiOutlinedInput-root": {
                      background: "transparent !important",
                      "& .MuiSelect-outlined": {
                        minHeight: "auto !important",
                        zIndex: 1
                      },
                      "& svg": {
                        position: "absolute",
                        right: 2,
                        zIndex: 0
                      }
                    },
                  }}
                  // onClick={() => setOpen(true)}
                  SelectProps={{
                    IconComponent: open ? ArrowDownNew : ArrowUpNew,
                    onOpen: () => setOpen(true),
                    onClose: () => setOpen(false),
                    // open: open,
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          // top: '335px !important',
                          // left: "465px !important",
                          transform: 'translateY(15px) translateX(-10px) !important',
                          width: "166px",
                          borderRadius: '12px', // Customize the border radius
                        },
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    },
                  }}
                  select
                  variant="outlined"
                  value={radioValue || "INSTANT"}
                  onChange={(e) => {
                    if (radioValue !== e.target.value) {
                      if (e.target.value === "REGULARLY_MONTH") {
                        setdayToSend(1)
                      }
                      if (e.target.value === "REGULARLY_WEEK") {
                        setdayToSend("MONDAY")
                      }
                    }
                    setRadioValue(e.target.value);
                    if (e.target.value === "REGULARLY_MONTH") {
                      setSelectedVal("LAST_THIRTY_DAYS")
                    }
                    if (e.target.value === "REGULARLY_WEEK") {
                      setSelectedVal("LAST_SEVEN_DAYS")
                    }
                    if (e.target.value === "REGULARLY_WEEK" || e.target.value === "REGULARLY_MONTH") {
                      setSelectionModal(true)
                    }
                  }}
                >
                  {[
                    {
                      label: "Doesn't repeat",
                      value: "INSTANT"
                    },
                    {
                      label: "Weekly",
                      value: "REGULARLY_WEEK"
                    },
                    {
                      label: "Monthly",
                      value: "REGULARLY_MONTH"
                    },
                  ].map((it) => (
                    <StyledMenuItem
                      sx={{
                        "& .MuiTouchRipple-root": {
                          display: "none"
                        },

                      }}
                      className="someclass"
                      value={it.value.toUpperCase()}
                      key={it.value.toUpperCase()}
                    >
                      {it.label}
                    </StyledMenuItem>
                  ))}
                </TextField>
                {dayToSend && seelctedVal && radioValue !== "INSTANT" && <>
                  <Typography
                    sx={{
                      color: palette.primary.light,
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "120% !important",
                      // textAlign: "center !important",
                      fontWeight: `400 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                    component={"span"}
                  >
                    , on
                  </Typography>
                  <Box display={"flex"} sx={{
                    cursor: "pointer"
                  }} onClick={() => {
                    setSelectionModal(true)
                  }} alignItems={"center"} gap={"8px"} borderRadius={"8px"} bgcolor={"#F7F9FA"} border={"1px solid #DCE5E0"} height={"48px"} padding={"0px 10px"}>
                    <Typography
                      sx={{
                        color: palette.primary.light,
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "120% !important",
                        // textAlign: "center !important",
                        fontWeight: `400 !important`,
                        "&.MuiTypography-root": {
                          "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(16)} !important`,
                            lineHeight: "100% !important",
                          },
                        },
                      }}
                    >

                      {radioValue === "REGULARLY_WEEK" ? capitalizeFirstLetter(dayToSend) : dayToSend + 'th'}
                    </Typography>
                    <Calendar color="#A0A0A1" />
                  </Box>
                </>}
              </Box>
              <Typography
                sx={{
                  color: palette.primary.light,
                  fontSize: `${pxToRem(16)} !important`,
                  lineHeight: "100% !important",
                  // textAlign: "center !important",
                  fontWeight: `600 !important`,
                  "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "100% !important",
                    },
                  },
                  marginTop: "40px",
                  marginBottom: "16px",
                }}
              >

                {"Review the content before sending"}
              </Typography>
              {ReviewData({
                contacts: allContacts[0].contacts.filter((it) =>
                  selectedNodes.includes(it._id)
                ),
                outcomeList: allDefaultStates[0].items.filter((it) =>
                  selectedOutInflu.includes(it._id)
                ),
                influenceList: allDefaultStates[1].items.filter((it) =>
                  selectedOutInflu.includes(it._id)
                ),
              }).map((it) => (
                <div style={{ marginBottom: "20px" }}>
                  <span className={classes.reviewTitle}>
                    {it.title} ({it.count}):
                  </span>
                  <span className={classes.reviewDescription}>
                    {it.description}
                  </span>
                </div>
              ))}
              <ReportDetails isfromshare={true} andViewOnly={true} />
            </>
          ) : null}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: showSuccessMessage ? "center" : "space-between",
              // padding: "0px 6px 6px",
            }}
          >
            {showSuccessMessage ? (
              <Typography
                sx={{
                  color: palette.primary.light,
                  fontSize: `${pxToRem(22)} !important`,
                  lineHeight: "120% !important",
                  // textAlign: "center !important",
                  fontWeight: `500 !important`,
                  "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(22)} !important`,
                      lineHeight: "100% !important",
                    },
                  },
                }}
              >
                <RightIconSwitch color="var(--color-new-main)" />{" "}
                {showSuccessMessage}.
              </Typography>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px"
                  }}
                >
                  {step === 1 && (
                    <>
                      <NewButton
                        variant="outlined"
                        padding="7px  !important"
                        boxPadding={"0px"}
                        textColor={"var(--color-new-main)"}
                        buttonText="Add Contact"
                        borderRadius="10px"
                        borderColor="var(--color-new-main)"
                        onClick={() => setVisibleAddContactModal(true)}
                        fontSize={pxToRem(16)}
                        fontWeight={600}
                        extraSx={{
                          ":hover": {
                            boxShadow: '0px 4px 4px 0px #00000040',
                            backgroundColor: "#fff"
                          }
                        }}
                      />
                      <NewButton
                        variant="outlined"
                        padding="7px  !important"
                        boxPadding={"0px"}
                        textColor={"var(--color-new-main)"}
                        buttonText="Manage contact"
                        borderRadius="10px"
                        borderColor="var(--color-new-main)"
                        onClick={() => setVisibleManageContactModal(true)}
                        fontSize={pxToRem(16)}
                        fontWeight={600}
                        extraSx={{
                          ":hover": {
                            boxShadow: '0px 4px 4px 0px #00000040',
                            backgroundColor: "#fff"
                          }
                        }}
                      />{" "}
                    </>
                  )}
                </Box>

                <NewButton
                  variant="contained"
                  buttonText={step === 3 ? "Confirm" : "Next"}
                  isDisabled={disabledButton}
                  onClick={() => {
                    if (step === 3) {
                      shareReport();
                    } else {
                      setStep((prvState) => prvState + 1);
                    }
                  }}
                />
              </>
            )}
          </Box>
        </Grid>
        {/* <Divider
          orientation="vertical"
          flexItem
          style={{
            margin: "0 30px",
          }}
        />

        <Grid item xs={7}>
          <Typography variant="h6" component="h6">
            How often do you want to share?
          </Typography>
          <RadioGroup
            name="radio-buttons-group"
            value={radioValue}
            onChange={({ target: { value } }) => setRadioValue(value)}
          >
            <FormControlLabel
              value="immediatly"
              control={<CustomRadio />}
              label="Send immediatly and only once"
            />
            {!xVersion && (
              <FormControlLabel
                value="regularly"
                control={<CustomRadio />}
                disabled
                label="Send regularly"
              />
            )}
          </RadioGroup>
          {radioValue === "regularly" ? (
            <Grid container className={classes.radioOptionDiv}>
              <Grid item xs={12} md={5} lg={4}>
                <Grid
                  container
                  className={classes.radioOptionDiv}
                  display="flex"
                  alignItems={"center"}
                >
                  <Grid item xs={2}>
                    <Typography
                      variant="h5"
                      className={classes.radioOptionDivText}
                    >
                      Every
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      InputProps={{ inputProps: { maxLength: 2 } }}
                      className={classes.raioOptionInputs}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className={clsx(classes.raioOptionSelect, "weekSelect")}
                      fullWidth
                      select
                      variant="outlined"
                      // value={week}
                      // onChange={(e) => handleChangeWeek(e)}
                    >
                      <MenuItem value={"Last Week"}>Last Week</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                      <MenuItem value={"Next Week"}>Next Week</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={7}>
                <Grid
                  container
                  className={classes.radioOptionDiv}
                  display="flex"
                  alignItems={"center"}
                >
                  <Grid item xs={2}>
                    <Typography
                      variant="h5"
                      className={classes.radioOptionDivText}
                    >
                      , on
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      className={clsx(classes.raioOptionSelect, "weekSelect")}
                      fullWidth
                      select
                      variant="outlined"

                      // value={week}
                      // onChange={(e) => handleChangeWeek(e)}
                    >
                      {[
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ].map((it) => (
                        <MenuItem value={it}>{it}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={5}>
                    <Box display="flex" alignItems="center">
                      <TextField
                        // className={`${classes.marginRight} textField`}
                        id="outlined-basic"
                        type="number"
                        InputProps={{
                          inputProps: { min: "0", max: "23", step: "1" },
                        }}
                        variant="outlined"
                        size="small"
                        className={classes.raioOptionInputs}
                        // value={hour || ""}
                        onChange={(e) => {
                          // setHour(e.target.value);
                        }}
                      />
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{ marginBottom: "1.3125rem" }}
                        // className={classes.raioOptionInputs}
                        // className={`${classes.influenceCaption} ${classes.padding}`}
                      >
                        :
                      </Typography>
                      <TextField
                        className={classes.raioOptionInputs}
                        // className={`${classes.marginLeft} textField`}
                        id="outlined-basic"
                        type="number"
                        size="small"
                        InputProps={{
                          inputProps: { min: "0", max: "59", step: "1" },
                        }}
                        variant="outlined"
                        // value={minute || ""}
                        onChange={(e) => {
                          // setMinute(e.target.value);
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Typography
            variant="h6"
            component="h6"
            style={{
              marginTop: "40px",
            }}
          >
            Review the content before sending
          </Typography>
          {ReviewData(IUserTypeState).map((it) => (
            <div style={{ marginBottom: "20px" }}>
              <span className={classes.reviewTitle}>
                {it.title} ({it.count}):
              </span>
              <span className={classes.reviewDescription}>
                {it.description}
              </span>
            </div>
          ))}
          <Button
            autoFocus
            disabled={selectedNodes.length <= 0}
            onClick={shareReport}
            type="submit"
            variant="contained"
            className="button-after"
            sx={{
              width: "144px !important",
              float: "right !important",
              right: "40px !important",
              padding: "15px !important",
              borderRadius: "10px !important",
              color: `${palette.common.white} !important`,
              "&.Mui-disabled": {
                color: "#00000042 !important",
              },
            }}
          >
            {"Send"}
          </Button>
        </Grid> */}
      </Grid>
      <SelecrtionDialogue handleOpenSelectionModal={handleOpenSelectionModal} radioValue={radioValue} setSelectionModal={setSelectionModal}
        seelctedVal={seelctedVal} setSelectedVal={setSelectedVal} dayToSend={dayToSend} setdayToSend={setdayToSend} />
    </div>
  );
};

export default SocialReportStepper;
