/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
} from "@mui/material";
import clsx from "clsx";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";

// import palette from "../../theme/palette";
// import ProgressStepper from "./progressStepper";
import ModalCloseIcon from "../../../assets/images/icon/modalCloseIconBlack";
import { ContactsDataJson as contacts, ReviewData } from "./contactsData";
import { ButtonLoader } from "../../../components/buttonLoader";
import palette from "../../../theme/palette";
import AddGroupModal from "./AddGroupModal";
import ThreeDotsIcon from "../../../assets/images/icon/ThreeDotsIcon";
import { ContactsContext } from "../../../context/userIContacts";
import { contactsService } from "../../../services/contactsService";
import { UserTypeContext } from "../../../context/userStatus";
import AddContactModal from "./AddContactModal";
import NewButton from "../../../components/NewButton";
// import { trackingService } from "../../services/tracking";
// import { UserTypeContext } from "../../context/userStatus";
// import { ButtonLoader } from "../../components/buttonLoader";
// import OutcomeclosePopup from "./OutcomeclosePopup";
// import DeleteIcon from "../../assets/images/icon/DeleteIcon";
// import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const useStyles = makeStyles((theme) => ({
  contactButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px",
    gap: "10px",

    height: "36px",

    /* BackgroundColors/PureWhite */

    background: "#FFFFFF",
    /* BackgroundColors/GhostButtonBorder */

    border: "1.5px solid rgba(17, 115, 74, 0.4)",
    borderRadius: "6px",
    marginBottom: "10px !important",
    margin: "10px 5px !important",
    color: "#3E4240 !important",
    fontWeight: 400,
    fontSize: "16px!important",
    lineHeight: "100%!important",
  },
  groupDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "25px 0",
  },
  namegroupDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "16px 0",
  },
  groupDivNameText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "#3E4240",
    margin: "4px 0",
  },
  groupDivEmailText: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "#9BA19D",
    margin: "4px 0",
  },
  groupDivText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* PrimaryColors/PineGreen */

    color: "#3E4240",
  },
  selectedTaxtColor: {
    color: "#11734A",
  },
  pageTitle: {
    marginBottom: "16px!important",
  },
  raioOptionInputs: {
    height: "48px",

    /* BackgroundColors/GreenyAreaBG */

    /* BackgroundColors/Dividers */

    border: "1px solid #DCE6E0",
    borderRadius: "8px",
    padding: "0 4px",
    "& .MuiOutlinedInput-root": {
      margin: "auto",
      "& input": {
        padding: "2px !important",
      },
    },
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& button:nth-child(2)": {
      marginLeft: "24px",
    },
  },
  nameEmailDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
}));
interface ManageContact {
  visibleManageContactModal: boolean;
  setVisibleManageContactModal: (val: boolean) => void;
  showLoadingBtn: boolean;
  setShowLoadingBtn: (val: boolean) => void;
  setShowSnakbar: (val: boolean) => void;
  setMsgType: (val: string) => void;
  setSnakbarMsg: (val: string) => void;
  getAllContacts: () => void;
}

const ManageContactModal = (props: ManageContact) => {
  const classes = useStyles();
  const [visibleAddGroupModal, setVisibleAddGroupModal] = useState(false);
  const { state: IContactTypeState, dispatch: contactsDispatch } =
    useContext(ContactsContext);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [visibleAddContactModal, setVisibleAddContactModal] = useState(false);
  const [isAddInGroup, setIsAddInGroup] = useState(false);
  const [radioValue, setRadioValue] = useState("immediatly");
  const [updateGroupInfo, setUpdateGroupInfo] = useState(null);
  const [selectedAnchor, setSelectedAnchor] = useState(null);
  // const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const { showLoadingBtn, setShowLoadingBtn } = props;
  const [outcomeNotes, setOutcomeNotes] = useState<any>("");
  const [item, setItem] = useState<any>();
  const [editData, setEditData] = useState<any>();
  const [openOutcomeclosePopup, setopenOutcomeclosePopup] = useState(false);
  const [openOutcomeDeletePopup, setopenOutcomeDeletePopup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    category?: any,
    all = false
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedAnchor(all ? "all" : category._id);
    setUpdateGroupInfo(all ? null : category);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElName, setAnchorElName] = React.useState<null | HTMLElement>(
    null
  );
  const openName = Boolean(anchorElName);
  const [contacts, setContacts] = useState([]);
  const handleClickName = (
    event: React.MouseEvent<HTMLElement>,
    category: any
  ) => {
    setAnchorElName(event.currentTarget);
    setEditData(category);
  };
  const handleCloseName = () => {
    setAnchorElName(null);
  };
  const { setShowSnakbar, setMsgType, setSnakbarMsg } = props;
  const handleAddGroup = (values, update) => {
    setShowLoadingBtn(true);
    const foundObject = [...IContactTypeState.groupContacts].find(
      (it) => it.groupName === values.groupName
    );

    if (foundObject && foundObject._id !== values.id) {
      setShowLoadingBtn(false);
      setShowSnakbar(true);
      setMsgType("error");
      setSnakbarMsg("Group is already added");
      return;
    }
    contactsService[update ? "updateGroup" : "createUpdateGroup"](
      values,
      IUserTypeState.token
    )
      .then((data) => {
        setVisibleAddGroupModal(false);
        setVisibleAddContactModal(false);
        props.getAllContacts();
        setEditData({});
        setUpdateGroupInfo({});
        setShowSnakbar(true);
        setMsgType("success");
        setSnakbarMsg(data?.description);
      })
      .catch((err) => {
        if (err?.description) {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(err?.description);
        }
      })
      .finally(() => {
        setShowLoadingBtn(false);
      });
  };
  const handleAddContact = (values, update) => {
    setShowLoadingBtn(true);
    if (isAddInGroup) {
      const foundObject = [...updateGroupInfo?.contacts].find(
        (it) => it.email === values.email
      );
      if (foundObject && foundObject._id !== values.id) {
        setShowLoadingBtn(false);
        setShowSnakbar(true);
        setMsgType("error");
        setSnakbarMsg("Contact is already added");
        return;
      }
      const objToUpdate = { ...updateGroupInfo };
      objToUpdate.id = objToUpdate?._id;
      objToUpdate.contacts = objToUpdate?.contacts || [];
      objToUpdate.contacts.push(values);
      contactsService
        .updateGroup(objToUpdate, IUserTypeState.token)
        .then((data) => {
          setVisibleAddContactModal(false);
          setIsAddInGroup(false);
          setEditData({});
          setUpdateGroupInfo({});
          props.getAllContacts();
          setShowSnakbar(true);
          setMsgType("success");
          setSnakbarMsg(data?.description);
        })
        .catch((err) => {
          if (err?.description) {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg(err?.description);
          }
        })
        .finally(() => {
          setShowLoadingBtn(false);
        });
    } else {
      if (!selectedGroup || selectedGroup === "all") {
        const foundObject = [...IContactTypeState?.allContacts].find(
          (it) => it.email === values.email
        );
        if (foundObject && foundObject._id !== values.id) {
          setShowLoadingBtn(false);
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg("Contact is already added");
          return;
        }
        contactsService[update ? "updateContact" : "createUpdateContact"](
          values,
          IUserTypeState.token
        )
          .then((data) => {
            setVisibleAddContactModal(false);
            props.getAllContacts();
            setShowSnakbar(true);
            setEditData({});
            setUpdateGroupInfo({});
            setMsgType("success");
            setSnakbarMsg(data?.description);
          })
          .catch((err) => {
            if (err?.description) {
              setShowSnakbar(true);
              setMsgType("error");
              setSnakbarMsg(err?.description);
            }
          })
          .finally(() => {
            setShowLoadingBtn(false);
          });
      } else {
        const foundGroup = [...IContactTypeState.groupContacts].find(
          (it) => it._id === selectedGroup
        );
        if (values.id) {
          const foundObject = [...foundGroup.contacts].find(
            (it) => it.email === values.email
          );
          if (foundObject && foundObject._id !== values.id) {
            setShowLoadingBtn(false);
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Contact is already added");
            return;
          }
          const foundIndex = foundGroup.contacts.findIndex(
            (it) => it._id === values.id
          );
          if (foundIndex > -1) {
            foundGroup.contacts[foundIndex] = {
              ...foundGroup.contacts[foundIndex],
              ...values,
            };
          } else {
            foundGroup.contacts.push(values);
          }
        } else {
          foundGroup.contacts.push(values);
        }
        handleAddGroup(
          {
            ...foundGroup,
            id: { ...(foundGroup || {}) }?._id,
          },
          { ...(foundGroup || {}) }?._id
        );
      }
    }
  };
  const handleDeleteContact = (values) => {
    if (!selectedGroup || selectedGroup === "all") {
      contactsService
        .deleteContact(values, IUserTypeState.token)
        .then((data) => {
          props.getAllContacts();
          setShowSnakbar(true);
          setEditData({});
          setUpdateGroupInfo({});
          setMsgType("success");
          setSnakbarMsg(data?.description);
        })
        .catch((err) => {
          if (err?.description) {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg(err?.description);
          }
        })
        .finally(() => {});
    } else {
      const foundGroup = [...IContactTypeState.groupContacts].find(
        (it) => it._id === selectedGroup
      );
      if (values.id) {
        foundGroup.contacts = foundGroup.contacts.filter(
          (it) => it._id !== values.id
        );
      }
      handleAddGroup(
        {
          ...foundGroup,
          id: { ...(foundGroup || {}) }?._id,
        },
        { ...(foundGroup || {}) }?._id
      );
    }
  };
  const handleDeleteGroup = (values) => {
    contactsService
      .deleteGroup(values, IUserTypeState.token)
      .then((data) => {
        props.getAllContacts();
        setShowSnakbar(true);
        setMsgType("success");
        setSnakbarMsg(data?.description);
      })
      .catch((err) => {
        if (err?.description) {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(err?.description);
        }
      })
      .finally(() => {});
  };
  useEffect(() => {
    let filteredContacts = [];
    if (selectedGroup === "all") {
      filteredContacts = [...IContactTypeState.allContacts];
    } else {
      filteredContacts = [...IContactTypeState.groupContacts].find(
        (it) => it._id === selectedGroup
      )?.contacts;
    }
    setContacts(filteredContacts);
  }, [
    selectedGroup,
    IContactTypeState.allContacts,
    IContactTypeState.groupContacts,
  ]);

  return (
    <Grid>
      <Dialog
        onClose={() => props.setVisibleManageContactModal(false)}
        open={props.visibleManageContactModal}
        className="dialogWrapper dialogWrapper-addContact dialogWrapper-mobileScreen"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            paddingBottom: "10px",
          },
        }}
      >
        <DialogTitle>
          <Typography
            sx={{ marginBottom: "40px" }}
            variant="h6"
            component="span"
          >
            Manage Contacts
          </Typography>

          {/* <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            // onClick={handleCloseStartModal}
            onClick={() => props.handleShareModalOpen(false)}
          >
            <ModalCloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h6" component="h6">
                Contact Groups
              </Typography>
              <div className={classes.groupDiv}>
                <span
                  onClick={() => setSelectedGroup("all")}
                  className={clsx(
                    classes.groupDivText,
                    selectedGroup === "all" ? classes.selectedTaxtColor : ""
                  )}
                >
                  {"All "}{" "}
                  {IContactTypeState.allContacts
                    ? "(" + IContactTypeState.allContacts.length + ")"
                    : null}
                </span>
                <span
                  onClick={(e) => handleClick(e, null, true)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDotsIcon />
                </span>
              </div>
              {[...IContactTypeState.groupContacts].map((it) => {
                return (
                  <div className={classes.groupDiv}>
                    <span
                      onClick={() => setSelectedGroup(it._id)}
                      className={clsx(
                        classes.groupDivText,
                        selectedGroup === it._id
                          ? classes.selectedTaxtColor
                          : ""
                      )}
                    >
                      {it.groupName}{" "}
                      {it.contacts ? "(" + it.contacts.length + ")" : null}
                    </span>
                    <span
                      onClick={(e) => handleClick(e, it)}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ThreeDotsIcon />
                    </span>
                  </div>
                );
              })}
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                // className={classes.dropMenu}
                PaperProps={{
                  elevation: 0,
                }}
                transformOrigin={{
                  horizontal: "center",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "center",
                  vertical: "bottom",
                }}
              >
                {selectedAnchor === "all"
                  ? [
                      <MenuItem
                        key="add"
                        onClick={() => {
                          setIsAddInGroup(false);

                          setVisibleAddContactModal(true);
                          // setUpdateGroupInfo()
                          // psetShowOutcome(true);
                          // psetStartModal(true);
                          // psetAddOutcomeTracking(true);
                        }}
                      >
                        Add Contact
                      </MenuItem>,
                    ]
                  : [
                      <MenuItem
                        onClick={() => {
                          setVisibleAddGroupModal(true);
                          // setUpdateGroupInfo()
                          // psetShowOutcome(true);
                          // psetStartModal(true);
                          // psetAddOutcomeTracking(true);
                        }}
                        key="rename"
                      >
                        Rename Group
                      </MenuItem>,
                      <MenuItem
                        onClick={() => {
                          setIsAddInGroup(true);
                          setVisibleAddContactModal(true);
                          // setUpdateGroupInfo()
                          // psetShowOutcome(true);
                          // psetStartModal(true);
                          // psetAddOutcomeTracking(true);
                        }}
                        key="add"
                      >
                        Add Contact
                      </MenuItem>,
                      <MenuItem
                        onClick={() => {
                          handleDeleteGroup({
                            id: updateGroupInfo?._id,
                          });
                          // setUpdateGroupInfo()
                          // psetShowOutcome(true);
                          // psetStartModal(true);
                          // psetAddOutcomeTracking(true);
                        }}
                        key="delete"
                      >
                        Delete Group
                      </MenuItem>,
                    ]}
                {/* <MenuItem>Rename</MenuItem> */}
              </Menu>
              <Button
                variant="outlined"
                className={classes.contactButton}
                onClick={() => {
                  setSelectedAnchor("all");
                  setUpdateGroupInfo(null);
                  setVisibleAddGroupModal(true);
                }}
              >
                Add Group
              </Button>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                margin: "0 30px",
              }}
            />

            <Grid item xs={6}>
              <Typography variant="h6" component="h6">
                Name
              </Typography>
              {[...(contacts || [])].map((it) => {
                return (
                  <div className={classes.namegroupDiv}>
                    <div className={classes.nameEmailDiv}>
                      <span className={clsx(classes.groupDivNameText)}>
                        {it.name}
                      </span>
                      <span className={clsx(classes.groupDivEmailText)}>
                        {it.email}
                      </span>
                    </div>

                    <span
                      onClick={(e) => handleClickName(e, it)}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ThreeDotsIcon />
                    </span>
                  </div>
                );
              })}
              <Menu
                anchorEl={anchorElName}
                id="account-menu"
                open={openName}
                onClose={handleCloseName}
                onClick={handleCloseName}
                // className={classes.dropMenu}
                PaperProps={{
                  elevation: 0,
                }}
                transformOrigin={{
                  horizontal: "center",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "center",
                  vertical: "bottom",
                }}
              >
                {/* <MenuItem>Rename</MenuItem> */}
                <MenuItem
                  onClick={() => {
                    setVisibleAddContactModal(true);
                    // psetShowOutcome(true);
                    // psetStartModal(true);
                    // psetAddOutcomeTracking(true);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDeleteContact({
                      id: editData?._id,
                    });
                    // psetShowOutcome(true);
                    // psetStartModal(true);
                    // psetAddOutcomeTracking(true);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box className={classes.bottomButton}>
            <NewButton
              buttonText="Cancel"
              variant="outlined"
              onClick={() => props.setVisibleManageContactModal(false)}
            />
            <NewButton
              buttonText="Save"
              isLoadong={showLoadingBtn}
              variant="contained"
              type="submit"
              onClick={() => props.setVisibleManageContactModal(false)}
            />
          </Box>
        </DialogActions>

        {visibleAddGroupModal && (
          <AddGroupModal
            visibleAddGroupModal={visibleAddGroupModal}
            setVisibleAddGroupModal={(val) => {
              setVisibleAddGroupModal(val);
              setUpdateGroupInfo({});
            }}
            handleAddGroup={handleAddGroup}
            showLoadingBtn={props.showLoadingBtn}
            setShowLoadingBtn={props.setShowLoadingBtn}
            updateGroupInfo={updateGroupInfo}
          />
        )}
        {visibleAddContactModal && (
          <AddContactModal
            visibleAddContactModal={visibleAddContactModal}
            setVisibleAddContactModal={(val) => {
              setVisibleAddContactModal(val);
              setEditData({});
            }}
            handleAddContact={handleAddContact}
            showLoadingBtn={showLoadingBtn}
            setShowLoadingBtn={setShowLoadingBtn}
            editData={editData}
            handleAddGroup={handleAddGroup}
            updateGroupInfo={updateGroupInfo}
            selectedAnchor={selectedAnchor}
            isAddInGroup={isAddInGroup}
          />
        )}
      </Dialog>
    </Grid>
  );
};
export default ManageContactModal;
