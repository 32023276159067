import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const outcomeCreate = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.trackingOutcome}/create`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const outcomeSearch = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.outcomeSearch}`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getOutcomeRes = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.trackingOutcome}/get`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteOutcomeRes = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.trackingOutcome}/delete`, bodyData, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteInfluenceRes = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.trackingInfluence}/delete`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteRefQuesRes = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.tracking}/reflective-response/delete`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const influenceCreate = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.trackingInfluence}/create`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const influenceSearch = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.influenceSearch}`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getInfluenceRes = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.trackingInfluence}/get`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getJourneyList = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.journey}/get`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getPersonalJourneyList = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.journey}/get-category`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const refQuesCreate = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.tracking}/reflective-response/create`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const signupCompletedBoarding = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.signupcompleted}`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};
const resSearch = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.reflectiveSearch}`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getRefQues = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.tracking}/reflective-response/get`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getVersionHistory = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.tracking}/versioning/get-version-details`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const restoreVersionHistory = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.tracking}/versioning/restore`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteVersionHistory = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.tracking}/versioning/delete`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteUserAccount = async (token: string) => {
  const { data } = await axios
  .post(`${baseEndpoints.userProfile}/delete`, {}, {
    headers: {
      "x-access-token": token ? token : headerToken,
    },
  })
  .then((response) => {
    return response;
  });
return data;
}

const getFAQAllTitle = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.getFaq}/title`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const requestafeature = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.feature}/create`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
}

const giveFeedback = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.feedback}/create`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
}

const reportabug = async (token: string, formData, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.bug}/create`, formData, {
      headers: {
        "x-access-token": token ? token : headerToken,
        'Content-Type': 'multipart/form-data'
      },
      params: bodyData
    })
    .then((response) => {
      return response;
    });
  return data;
}

const getFAQs = async (token: string, bodyData: any) => {
  const { data } = await axios
    .post(`${baseEndpoints.getFaq}/list`, bodyData, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const trackingService = {
  outcomeCreate,
  getOutcomeRes,
  influenceCreate,
  getInfluenceRes,
  getJourneyList,
  getPersonalJourneyList,
  refQuesCreate,
  getRefQues,
  getVersionHistory,
  restoreVersionHistory,
  deleteVersionHistory,
  outcomeSearch,
  resSearch,
  influenceSearch,
  getFAQAllTitle,
  getFAQs,
  deleteOutcomeRes,
  deleteInfluenceRes,
  deleteRefQuesRes,
  requestafeature,
  reportabug,
  giveFeedback,
  deleteUserAccount,
  signupCompletedBoarding
};
