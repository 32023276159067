import { Box, Dialog, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { pxToRem } from "../../theme/typography"
import NewButton from "../../components/NewButton"
import ProgressBar from "./progressbar"
import { userService } from "../../services/auth"
import CSVFileIcon from "../../assets/images/icon/CSVFileIcon"
import StepperUpload from "./stepperUpload"
let InternalProgress = 0

let newDesign = false
const UploadFile = ({
    visibleModal,
    closeModal,
    isFromExport = false
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [hasUploadedFile, setUploadedFile] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const [hasSuccess, sethasSuccess] = useState(false);
    const [progress, setProgress] = useState(0);
    const handleDragEnter = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };
    useEffect(() => {
        return () => {
            setIsDragging(false)
            setUploadedFile(false)
            setApiCalled(false)
            setProgress(0)
            sethasSuccess(false)
        }
    }, [])
    const runInterval = () => {
        const interval = setInterval(() => {
            console.log("progress <= 100", InternalProgress, InternalProgress <= 100);

            if (InternalProgress < 100) {
                InternalProgress += 20
                setProgress((prevState) => prevState + 20)
            } else {
                clearInterval(interval)
                sethasSuccess(true)
                setApiCalled(true)
            }
        }, 500)
    }
    useEffect(() => {
        if (isFromExport) {
            setUploadedFile(true)
            runInterval()
        }
        return () => {
            setProgress(0)
            InternalProgress = 0
        }
    }, [isFromExport])
    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleUploadFile = (file) => {
        setUploadedFile(true)
        console.log(file);
        const token = localStorage.getItem("userDetails")
        const formData = new FormData()
        formData.append("provision", file)
        userService.uploadFileToServer(formData, token, (progress) => {
            console.log("progress", progress);

            setProgress(progress)
        }).then((data) => {
            sethasSuccess(true)
            setApiCalled(true)
        }).catch(() => {
            sethasSuccess(false)
            setApiCalled(true)

        })
    }



    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const file = event.dataTransfer.files[0];
        if (file) {
            handleUploadFile(file)
        }
    };


    return <Dialog
        disableEscapeKeyDown
        onClose={() => {
            setIsDragging(false)
            setUploadedFile(false)
            setApiCalled(false)
            setProgress(0)
            sethasSuccess(false)
            closeModal()
        }}
        open={visibleModal}
        className="dialogWrapper dialogWrapper-tracking dialogWrapper-tracking-cal"
        sx={{
            "& .MuiPaper-root": newDesign ? {
                padding: apiCalled ? '40px 32px 36px 32px' : "32px",
                gap: "0px",
                borderRadius: "12px",
                justify: "space-between",
                boxShadow: newDesign ? "0px 0px 64px 0px #190D1A1A" : "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
            } : {
                padding: apiCalled ? '40px 32px 36px 32px' : '48px 40px 48px 40px',
                gap: '48px',
                borderRadius: '20px',
                opacity: '0px',

            }
        }}
    >
        <Box sx={newDesign ? {
            gap: '32px',
            display: 'flex',
            flexDirection: "column"
        } : {
            gap: '40px',
            opacity: '0px',
            display: 'flex',
            flexDirection: "column"
        }}>
            {apiCalled ? (
                <Box sx={{
                    gap: '32px',
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    {hasSuccess ? (
                        <>
                            <Box sx={{
                                gap: '20px',
                                display: 'flex',
                                flexDirection: "column",
                                alignItems: "center"
                            }}>
                                <Typography
                                    variant="body1"
                                    component="div"
                                    sx={
                                        {
                                            color: '#3E4240',
                                            fontWeight: 600,
                                            fontSize: `${pxToRem(22)} !important`,
                                            lineHeight: "22px  !important",
                                            letterSpacing: '2%'
                                        }
                                    }
                                >
                                    {isFromExport ? "Your export request is being processed" : "Importing succeeded!"}
                                </Typography>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24" cy="24" r="22.5" stroke={isFromExport ? "var(--color-new-main)" : "#11734A"} stroke-width="3" />
                                    <path d="M21.4853 26.3137L15.8284 20.6569L13 23.4853L21.4853 31.9706L35.6274 17.8284L32.799 15L21.4853 26.3137Z" fill={isFromExport ? "var(--color-new-main)" : "#11734A"} />
                                </svg>
                                {isFromExport && <Typography
                                    variant="body1"
                                    component="div"
                                    sx={
                                        {
                                            color: '#9BA19D',
                                            fontWeight: 400,
                                            fontSize: `${pxToRem(16)} !important`,
                                            lineHeight: "20px  !important",
                                            letterSpacing: '2%',
                                            marginTop: '12px'
                                        }
                                    }
                                >
                                    {"You will receive the file via email shortly"}
                                </Typography>}
                            </Box>
                        </>
                    ) : <>
                        <Box sx={{
                            gap: '20px',
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Typography
                                variant="body1"
                                component="div"
                                sx={
                                    {
                                        color: 'var(--color-black)',
                                        fontWeight: 600,
                                        fontSize: `${pxToRem(22)} !important`,
                                        lineHeight: "31.2px  !important",
                                        letterSpacing: '2%'
                                    }
                                }
                            >
                                Importing failed...
                            </Typography>
                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47 10.5H7V16.5H47V10.5Z" fill="#F9D412" />
                                <line x1="3" y1="17.5" x2="47" y2="17.5" stroke="#11734A" stroke-width="3" />
                                <path d="M46.5 24.5V10C46.5 7.79086 44.7091 6 42.5 6H6.5C4.29086 6 2.5 7.79086 2.5 10V41.7975C2.5 41.9274 2.55052 42.0522 2.64088 42.1454L17.8527 57.8479C17.9468 57.9451 18.0764 58 18.2118 58H42.5C44.7091 58 46.5 56.2091 46.5 54V42.75" stroke="#11734A" stroke-width="3" />
                                <path d="M2.5 42H16.5C17.6046 42 18.5 42.8954 18.5 44V58L2.5 42Z" stroke="#11734A" stroke-width="3" stroke-linejoin="round" />
                                <path d="M18.5 33.5H61.5" stroke="#11734A" stroke-width="3" />
                                <path d="M54.5 26.5L61.5 33.5L54.5 40.5" stroke="#11734A" stroke-width="3" stroke-linejoin="round" />
                                <path d="M33.1328 27.8418L44.4465 39.1555M33.1328 39.1555L44.4465 27.8418" stroke="white" stroke-width="7" />
                                <path d="M34.1328 28.8418L43.4465 38.1555M34.1328 38.1555L43.4465 28.8418" stroke="#11734A" stroke-width="3" />
                            </svg>

                        </Box>
                        <Typography
                            variant="body1"
                            component="div"
                            sx={
                                {
                                    color: 'var(--color-black)',
                                    fontWeight: 400,
                                    fontSize: `${pxToRem(16)} !important`,
                                    lineHeight: "31.2px  !important",
                                    letterSpacing: '2%',
                                    display: "flex",
                                    flexWrap: 'nowrap',
                                    alignItems: "center",
                                    gap: '4px'
                                }
                            }
                        >
                            Please check you connection and  <Typography
                                variant="body1"
                                component="div"
                                onClick={() => {
                                    setIsDragging(false)
                                    setUploadedFile(false)
                                    setApiCalled(false)
                                    setProgress(0)
                                    sethasSuccess(false)
                                }}
                                sx={
                                    {
                                        color: 'var(--primary-color)',
                                        fontWeight: 400,
                                        cursor: "pointer",
                                        fontSize: `${pxToRem(16)} !important`,
                                        textDecoration: 'underline',
                                        lineHeight: "100%  !important",
                                    }
                                }
                            >
                                retry
                            </Typography>
                        </Typography>
                    </>}

                </Box>
            ) : hasUploadedFile ? (
                <Box sx={{
                    width: '480px',
                    gap: '16px',
                    display: 'flex',
                    flexDirection: "column"
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Typography
                            variant="body1"
                            component="div"
                            sx={
                                {
                                    color: 'var(--color-black)',
                                    fontWeight: 600,
                                    fontSize: `${pxToRem(22)} !important`,
                                    lineHeight: "31.2px  !important",
                                    letterSpacing: '2%'
                                }
                            }
                        >
                            {isFromExport ? "Generating export file..." : "Importing your data..."}
                        </Typography>
                        <Typography
                            variant="body1"
                            component="div"
                            sx={
                                {
                                    color: 'var(--color-black)',
                                    fontWeight: 600,
                                    fontSize: `${pxToRem(22)} !important`,
                                    lineHeight: "31.2px  !important",
                                    letterSpacing: '2%'
                                }
                            }
                        >
                            {progress}%
                        </Typography>
                    </Box>

                    <ProgressBar progress={progress} />
                </Box>

            ) : newDesign ? (

                <StepperUpload
                    onDragEnter={handleDragEnter}
                    onDragOver={(event) => event.preventDefault()}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    handleUploadFile={handleUploadFile}
                    isDragging={isDragging}
                />
            ) : (
                <Box sx={{
                    width: '480px',
                    height: '264px',
                    gap: '32px',
                    display: 'flex',
                    flexDirection: "column"
                }}>

                    <Typography
                        variant="body1"
                        component="div"
                        sx={
                            {
                                color: 'var(--color-black)',
                                fontWeight: 700,
                                fontSize: `${pxToRem(26)} !important`,
                                lineHeight: "31.2px  !important",
                                letterSpacing: '2%'
                            }
                        }
                    >
                        Import from a .csv file
                    </Typography>
                    <Box sx={{
                        width: '480px',
                        height: '201px',
                        borderRadius: '8px',
                        border: isDragging ? '2px dashed var(--primary-color)' : '2px dashed #DCE5E0',
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: "center",
                        position: 'relative',
                        backgroundColor: isDragging ? 'rgb(17 115 74 / 20%)' : 'transparent',
                    }} onDragEnter={handleDragEnter}
                        onDragOver={(event) => event.preventDefault()}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}>

                        <Box position={"absolute"} top={"20%"} bottom={"20%"}>
                            <CSVFileIcon color={isDragging ? "#11734A" : "#DCE5E0"} />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: 'center',
                            position: 'absolute',
                            bottom: "10px"
                        }}>
                            <Typography
                                variant="body1"
                                component="span"
                                sx={
                                    {
                                        color: 'var(--primary-color)',
                                        fontWeight: 400,
                                        fontSize: `${pxToRem(16)} !important`,
                                        lineHeight: `${pxToRem(20)}  !important`,
                                        textDecoration: 'underline'
                                    }
                                }
                            >
                                Open a CSV file
                            </Typography>
                            <Typography
                                variant="body1"
                                component="span"
                                sx={
                                    {
                                        color: '#9BA19D',
                                        fontWeight: 400,
                                        fontSize: `${pxToRem(16)} !important`,
                                        lineHeight: `${pxToRem(20)}  !important`,
                                    }
                                }
                            >
                                , or drag and drop it here
                            </Typography>
                        </Box>
                        <input
                            type="file"
                            accept=".csv, .xlsx"
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                opacity: 0,
                                cursor: 'pointer',
                            }}
                            onChange={(event) => {
                                const file = event.target.files[0];
                                if (file) {
                                    handleUploadFile(file)
                                }
                            }}
                        />
                    </Box>
                </Box>
            )}

            {!apiCalled && !newDesign && <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <NewButton
                    buttonText="Cancel"
                    // onClick={props?.handleSubmit}
                    onClick={() => {
                        setIsDragging(false)
                        setUploadedFile(false)
                        setApiCalled(false)
                        setProgress(0)
                        sethasSuccess(false)
                        closeModal()
                    }}
                    borderRadius="6px"
                    padding="12px 36px 12px 36px"
                    borderColor="var(--color-new-main)"
                    fontWeight={500}
                    variant="outlined"
                />
            </Box>}
        </Box>
    </Dialog>
}

export default UploadFile