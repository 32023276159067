import { Box, Dialog, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import React, { useState } from "react"
import palette from "../../../theme/palette"
import { pxToRem } from "../../../theme/typography"
import { styled } from "@mui/material/styles";
import NewButton from "../../../components/NewButton";
import moment from "moment";
const CustomRadio = styled(Radio)(({ theme }) => ({
    color: "#DCE6E0",
    borderRadius: "4px",
    height: "20px",
    width: "20px",
    "&.Mui-checked": {
        color: "var(--color-new-main)",
        borderRadius: "4px",
    },
}));

const SelecrtionDialogue = ({
    handleOpenSelectionModal, setSelectionModal,
    seelctedVal, setSelectedVal,
    dayToSend, setdayToSend,
    radioValue
}) => {
    console.log("radioValue", radioValue);
    const isMonth = radioValue === "REGULARLY_MONTH"
    return <Dialog
        // onClose={() => setSelectionModal(false)}
        open={handleOpenSelectionModal}
        className="dialogWrapper dialogWrapper-share dialogWrapper-mobileScreen"
        sx={{
            "& .MuiBackdrop-root": {
                backgroundColor: "rgba(25, 13, 26, 0.1)",
                backdropFilter: 'blur(2px)'
            },
            ".MuiPaper-root": {
                height: "402px",
                minWidth: "384px",
                width: "384px",
                padding: "24px !important",
            },
        }}
    >
        <Box width={"336px"} height={"298px"} display={"flex"} flexDirection={"column"} gap={"8px"}>
            <Box width={"336px"} height={"298px"} display={"flex"} flexDirection={"column"} gap={"16px"}>
                <Box width={"295px"} height={"20px"} display={"flex"} gap={"8px"}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_699_4680)">
                            <path d="M7.9987 14.6668C11.6806 14.6668 14.6654 11.6821 14.6654 8.00016C14.6654 4.31826 11.6806 1.3335 7.9987 1.3335C4.3168 1.3335 1.33203 4.31826 1.33203 8.00016C1.33203 11.6821 4.3168 14.6668 7.9987 14.6668Z" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8 4V8L10.6667 9.33333" stroke="#190D1A" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_699_4680">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <Typography
                        sx={{
                            color: "#190D1A",
                            fontSize: `${pxToRem(14)} !important`,
                            lineHeight: "120% !important",
                            // textAlign: "center !important",
                            fontWeight: `500 !important`,
                            "&.MuiTypography-root": {
                                "@media  (max-width: 600px) and (min-width: 100px)": {
                                    fontSize: `${pxToRem(14)} !important`,
                                    lineHeight: "100% !important",
                                },
                            },
                        }}
                    >
                        {"Frequency"}
                    </Typography>
                </Box>
                <Box width={"336px"} height={"262px"} display={"flex"} flexDirection={"column"} gap={"20px"}      >
                    <Box width={"336px"} height={"262px"} display={"flex"} flexDirection={"column"} gap={"16px"} padding={"16px"} sx={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }} border={"1px solid #F3F3F3"} borderRadius={"16px"}>
                        <SelectionBox isMonth={isMonth} />
                        <Typography
                            sx={{
                                color: "#767476",
                                fontSize: `${pxToRem(12)} !important`,
                                lineHeight: "120% !important",
                                // textAlign: "center !important",
                                fontWeight: `400 !important`,
                                "&.MuiTypography-root": {
                                    "@media  (max-width: 600px) and (min-width: 100px)": {
                                        fontSize: `${pxToRem(12)} !important`,
                                        lineHeight: "100% !important",
                                    },
                                },
                            }}
                        >
                            {"Which report do you want to share?"}
                        </Typography>
                        <RadioGroup
                            name="radio-buttons-group"
                            value={seelctedVal}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}
                            onChange={({ target: { value } }) => setSelectedVal(value)}
                        >
                            <FormControlLabel
                                value={isMonth ? "LAST_THIRTY_DAYS" : "LAST_SEVEN_DAYS"}
                                control={<CustomRadio />}
                                sx={{
                                    marginLeft: 0
                                }}
                                label={<Typography
                                    sx={{
                                        color: "#00000099",
                                        fontSize: `${pxToRem(12)} !important`,
                                        lineHeight: "120% !important",
                                        marginLeft: "10px",
                                        // textAlign: "center !important",
                                        fontWeight: `400 !important`,
                                        "&.MuiTypography-root": {
                                            "@media  (max-width: 600px) and (min-width: 100px)": {
                                                fontSize: `${pxToRem(12)} !important`,
                                                lineHeight: "100% !important",
                                            },
                                        },
                                    }}
                                >
                                    {isMonth ? "Last 30 days" : "Last 7 days"}
                                </Typography>}
                            />
                            <FormControlLabel
                                value={isMonth ? "LAST_MONTH" : "LAST_WEEK"}
                                control={<CustomRadio />}
                                sx={{
                                    marginLeft: 0
                                }}
                                label={<Typography
                                    sx={{
                                        color: "#00000099",
                                        fontSize: `${pxToRem(12)} !important`,
                                        lineHeight: "120% !important",
                                        marginLeft: "10px",
                                        // textAlign: "center !important",
                                        fontWeight: `400 !important`,
                                        "&.MuiTypography-root": {
                                            "@media  (max-width: 600px) and (min-width: 100px)": {
                                                fontSize: `${pxToRem(12)} !important`,
                                                lineHeight: "100% !important",
                                            },
                                        },
                                    }}
                                >
                                    {isMonth ? "Last Month" : "Last Week"}
                                </Typography>}
                            />
                        </RadioGroup>
                        <Box width={"304px"} display={"flex"} flexDirection={"column"} gap={"26px"}>
                            <Typography
                                sx={{
                                    color: "#767476",
                                    fontSize: `${pxToRem(12)} !important`,
                                    lineHeight: "120% !important",
                                    letterSpacing: "-1%",
                                    // textAlign: "center !important",
                                    fontWeight: `400 !important`,
                                    "&.MuiTypography-root": {
                                        "@media  (max-width: 600px) and (min-width: 100px)": {
                                            fontSize: `${pxToRem(12)} !important`,
                                            lineHeight: "100% !important",
                                        },
                                    },
                                }}
                            >
                                {isMonth ? "Select the date when your monthly report will be sent" : "Select the day when your weekly report will be sent"}
                            </Typography>

                            {isMonth ? <MonthSelection dayToSend={dayToSend} setdayToSend={setdayToSend} /> : <WeekSelection dayToSend={dayToSend} setdayToSend={setdayToSend} />}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} marginTop={"8px"}>
            <NewButton
                variant="contained"
                buttonText={"Apply"}
                padding="12px 20px 12px 20px"
                isDisabled={!dayToSend && !seelctedVal}
                onClick={() => {
                    setSelectionModal(false)
                }}
            />
        </Box>
    </Dialog>
}

export default SelecrtionDialogue

const WeekSelection = ({ dayToSend, setdayToSend }) => {
    const Weeks = ["Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",]
    const selected = dayToSend ? dayToSend.toUpperCase() : null
    return <Box width={"100%"} display={"flex"} gap={"0px"} border={"1px solid #F3F3F3"} borderRadius={"8px"}>
        {Weeks.map((it, index) => (
            <Box key={it} width={"calc(100% / 7)"} sx={{
                cursor: "pointer"
            }} onClick={() => {
                setdayToSend(it.toUpperCase());
            }} bgcolor={selected && selected === it.toUpperCase() ? "#D6F5C3" : "transparent"} borderRadius={!index ? "8px 0px 0px 8px" : index === (Weeks.length - 1) ? "0px 8px 8px 0px" : "0px"} borderRight={index === (Weeks.length - 1) ? null : "1px solid #F3F3F3"} padding={"18px 0px 18px 0px"}>{
                    <Typography
                        sx={{
                            color: "#767476",
                            fontSize: `${pxToRem(12)} !important`,
                            lineHeight: "120% !important",
                            letterSpacing: "-1%",
                            textAlign: "center !important",
                            fontWeight: `400 !important`,
                            "&.MuiTypography-root": {
                                "@media  (max-width: 600px) and (min-width: 100px)": {
                                    fontSize: `${pxToRem(12)} !important`,
                                    lineHeight: "100% !important",
                                },
                            },
                        }}
                    >
                        {it.slice(0, 3)}
                    </Typography>}</Box>
        ))}
    </Box>
}
const MonthSelection = ({ dayToSend, setdayToSend }) => {
    const defaultDateToday = moment().format("D")

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} >
                {[1, 2, 3, 4, 5, 6, 7].map(it => (
                    <Box height={"42.46px"} width={"42.46px"} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{
                        cursor: "pointer"
                    }} onClick={()=>{
                        setdayToSend(it);
                    }}>
                        <Box height={"39.81px"} width={"39.81px"} display={"flex"} alignItems={"center"} bgcolor={Number(dayToSend) === it ? "#D6F5C3" : "transparent"} justifyContent={"center"} borderRadius={"100%"} border={it === Number(defaultDateToday) ? "1px solid #A7DE85" : "none"}>
                            <Typography
                                sx={{
                                    color: "#767476",
                                    fontSize: `${pxToRem(12)} !important`,
                                    lineHeight: "120% !important",
                                    letterSpacing: "-1%",
                                    textAlign: "center !important",
                                    fontWeight: `400 !important`,
                                    "&.MuiTypography-root": {
                                        "@media  (max-width: 600px) and (min-width: 100px)": {
                                            fontSize: `${pxToRem(12)} !important`,
                                            lineHeight: "100% !important",
                                        },
                                    },
                                }}
                            >
                                {it}
                            </Typography>
                            {it === Number(defaultDateToday)}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box display={"flex"} >
                {[8, 9, 10, 11, 12, 13, 14].map(it => (
                    <Box height={"42.46px"} width={"42.46px"} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{
                        cursor: "pointer"
                    }} onClick={()=>{
                        setdayToSend(it);
                    }}>
                        <Box height={"39.81px"} width={"39.81px"} display={"flex"} alignItems={"center"} justifyContent={"center"} bgcolor={Number(dayToSend) === it ? "#D6F5C3" : "transparent"} borderRadius={"100%"} border={it === Number(defaultDateToday) ? "1px solid #A7DE85" : "none"}>
                            <Typography
                                sx={{
                                    color: "#767476",
                                    fontSize: `${pxToRem(12)} !important`,
                                    lineHeight: "120% !important",
                                    letterSpacing: "-1%",
                                    textAlign: "center !important",
                                    fontWeight: `400 !important`,
                                    "&.MuiTypography-root": {
                                        "@media  (max-width: 600px) and (min-width: 100px)": {
                                            fontSize: `${pxToRem(12)} !important`,
                                            lineHeight: "100% !important",
                                        },
                                    },
                                }}
                            >
                                {it}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box display={"flex"} >
                {[15, 16, 17, 18, 19, 20, 21].map(it => (
                    <Box height={"42.46px"} width={"42.46px"} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{
                        cursor: "pointer"
                    }} onClick={()=>{
                        setdayToSend(it);
                    }}>
                        <Box height={"39.81px"} width={"39.81px"} display={"flex"} alignItems={"center"} justifyContent={"center"} bgcolor={Number(dayToSend) === it ? "#D6F5C3" : "transparent"} borderRadius={"100%"} border={it === Number(defaultDateToday) ? "1px solid #A7DE85" : "none"}>
                            <Typography
                                sx={{
                                    color: "#767476",
                                    fontSize: `${pxToRem(12)} !important`,
                                    lineHeight: "120% !important",
                                    letterSpacing: "-1%",
                                    textAlign: "center !important",
                                    fontWeight: `400 !important`,
                                    "&.MuiTypography-root": {
                                        "@media  (max-width: 600px) and (min-width: 100px)": {
                                            fontSize: `${pxToRem(12)} !important`,
                                            lineHeight: "100% !important",
                                        },
                                    },
                                }}
                            >
                                {it}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box display={"flex"} >
                {[22, 23, 24, 25, 26, 27, 28].map(it => (
                    <Box height={"42.46px"} width={"42.46px"} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{
                        cursor: "pointer"
                    }} onClick={()=>{
                        setdayToSend(it);
                    }}>
                        <Box height={"39.81px"} width={"39.81px"} display={"flex"} alignItems={"center"} justifyContent={"center"} bgcolor={Number(dayToSend) === it ? "#D6F5C3" : "transparent"} borderRadius={"100%"} border={it === Number(defaultDateToday) ? "1px solid #A7DE85" : "none"}>
                            <Typography
                                sx={{
                                    color: "#767476",
                                    fontSize: `${pxToRem(12)} !important`,
                                    lineHeight: "120% !important",
                                    letterSpacing: "-1%",
                                    textAlign: "center !important",
                                    fontWeight: `400 !important`,
                                    "&.MuiTypography-root": {
                                        "@media  (max-width: 600px) and (min-width: 100px)": {
                                            fontSize: `${pxToRem(12)} !important`,
                                            lineHeight: "100% !important",
                                        },
                                    },
                                }}
                            >
                                {it}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box display={"flex"} >
                {[29, 30].map(it => (
                    <Box height={"42.46px"} width={"42.46px"} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{
                        cursor: "pointer"
                    }} onClick={()=>{
                        setdayToSend(it);
                    }}>
                        <Box height={"39.81px"} width={"39.81px"} display={"flex"} alignItems={"center"} justifyContent={"center"} bgcolor={Number(dayToSend) === it ? "#D6F5C3" : "transparent"} borderRadius={"100%"} border={it === Number(defaultDateToday) ? "1px solid #A7DE85" : "none"}>
                            <Typography
                                sx={{
                                    color: "#767476",
                                    fontSize: `${pxToRem(12)} !important`,
                                    lineHeight: "120% !important",
                                    letterSpacing: "-1%",
                                    textAlign: "center !important",
                                    fontWeight: `400 !important`,
                                    "&.MuiTypography-root": {
                                        "@media  (max-width: 600px) and (min-width: 100px)": {
                                            fontSize: `${pxToRem(12)} !important`,
                                            lineHeight: "100% !important",
                                        },
                                    },
                                }}
                            >
                                {it}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}
const SelectionBox = ({ isMonth = false }) => {
    return <Box width={"100%"} display={"flex"} bgcolor={"#F9F9F9"} gap={"0px"} padding={"2px"} border={"1px solid #F3F3F3"} borderRadius={"10px"}>
        <Box flexBasis={"50%"} height={"28px"} padding={"8px 12px 8px 12px"} sx={{
            cursor: "pointer"
        }} bgcolor={isMonth ? "transparent" : "var(--white-background)"} borderRadius={"8px"}>
            <Typography
                sx={{
                    color: "#000000",
                    fontSize: `${pxToRem(12)} !important`,
                    lineHeight: "120% !important",
                    textAlign: "center !important",
                    fontWeight: `500 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(12)} !important`,
                            lineHeight: "100% !important",
                        },
                    },
                }}
            >
                {"Weekly"}
            </Typography>
        </Box>
        <Box flexBasis={"50%"} height={"28px"} padding={"8px 12px 8px 12px"} sx={{
            cursor: "not-allowed"
        }} bgcolor={!isMonth ? "transparent" : "var(--white-background)"} borderRadius={"8px"}>
            <Typography
                sx={{
                    color: "#000000",
                    fontSize: `${pxToRem(12)} !important`,
                    lineHeight: "120% !important",
                    textAlign: "center !important",
                    fontWeight: `500 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(12)} !important`,
                            lineHeight: "100% !important",
                        },
                    },
                }}
            >
                {"Monthly"}
            </Typography>
        </Box>
    </Box>
}