import { Alert, Snackbar } from "@mui/material";
import * as React from "react";

interface SnackBarCompModal {
  showSnakbar: boolean;
  handleCloseSnakbar: any;
  snakbarMsg: string;
  type:any;
}

const SnackBarComp = (props: SnackBarCompModal) => {
  return (
    <Snackbar
      open={props.showSnakbar}
      autoHideDuration={3000}
      onClose={props.handleCloseSnakbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={props.type}>{props.snakbarMsg}</Alert>
    </Snackbar>
  );
};
export default SnackBarComp;
