import React, { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Badge, Box, Button, Grid, Typography } from "@mui/material";
import { userService } from "../../services/auth";
import { UserTypeContext } from "../../context/userStatus";
import { baseEndpoints, xVersion } from "../../services/config";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import ZapIcon from "../../assets/images/icon/Zap";
import ArchiveIcon from "../../assets/images/icon/ArchiveIcon";
import Foldericon from "../../assets/images/icon/FolderIcon";
import BellIcon from "../../assets/images/icon/BellIcon";
import PlaneIcon from "../../assets/images/icon/PlaneIcon";
import { ReportsTypeContext } from "../../context/userIReports";
import { setWeekReport } from "../../store/actions/reportTypeAction";
import Htmltoolip from "../../components/HTMLTooltip";
import { useNavigate } from "react-router-dom";
import HTMLTooltip from "../../components/HTMLTooltip";
import { pxToRem } from "../../theme/typography";
import { setUserObject } from "../../store/actions/userTypeAction";
import { UserNotificationContext } from "../../context/userNotification";
import {
  setAnchor,
  setXPos,
  setYPos,
  updateNotificationData,
  updateUnreadNotificationData,
} from "../../store/actions/notificationActions";
import HeaderPopover from "./HeaderPopover";
import { notificationService } from "../../services/notifications";
import palette from "../../theme/palette";
import StrikeIcon from "../../assets/images/icon/StrikeIcon";
import TrackTitle from "./TrackTitle";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 2px",
    fontSize: "0.50rem",
  },
}));
const DummyUser = require("../../assets/images/userDummy.png");
const useStyles = makeStyles((theme) => ({
  headerGrid: {
    padding: "20px 20px 0px 24px",
    alignItems: "center",
    gap: "48px",
  },
  headerNameGrid: {},
  headerTiele: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  icons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
  },
  rectange: {
    width: "1px",
    height: "24px",
    background: "#A0A0A1",
  },
  button: {
    display: "flex",
    padding: "15px 16px 15px 15px",
    alignItems: "center",
    gap: "6px",
    borderRadius: "100px",
    background: "#11734A",
  },
  iconRound: {
    display: "flex",
    width: "44px",
    height: "44px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    // border: "1px solid #F3F3F3",
    // background: "#FFF",
  },
  iconRoundCustom: {
    display: "flex",
    width: "44px",
    height: "44px",
    justifyContent: "center",
    alignItems: "center",
    gap: "2px",
    borderRadius: "100px",
    // border: "1px solid #F3F3F3",
    // background: "#FFF",
  },
  headerGridChild: {
    // "@media  (min-width: 1200px) ": {
    //     flexBasis: "56.25%",
    //     flexGrow: 0,
    //     maxWidth: "56.25%",
    // },
    // "@media  (min-width: 900px) ": {
    //     flexBasis: "56.25%",
    //     flexGrow: 0,
    //     maxWidth: "56.25%",
    // },
    // "@media  (min-width: 600px) ": {
    //     flexBasis: "56.25%",
    //     flexGrow: 0,
    //     maxWidth: "56.25%",
    // },
  },
  headerGridChild6: {
    // "@media  (min-width: 1200px) ": {
    //     flexBasis: "14.5% !important",
    //     flexGrow: 0,
    //     maxWidth: "14.5% !important",
    // },
    // "@media  (min-width: 900px) ": {
    //     flexBasis: "37.5%",
    //     flexGrow: 0,
    //     maxWidth: "37.5%",
    // },
    // "@media  (min-width: 600px) ": {
    //     flexBasis: "37.5%",
    //     flexGrow: 0,
    //     maxWidth: "37.5%",
    // },
  },
}));

const TrackHeader = (props: any) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [ImageFile, setImageFile] = useState(null);
  const [Uploaded, setUploaded] = useState(false);
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { dispatch: reportTypeDispatch } = useContext(ReportsTypeContext);
  const { state: INotificationState, dispatch: notificationDispatch } =
    useContext(UserNotificationContext);
  const { alreadyCalled, notifications, unreadnotifications } =
    INotificationState;
  const [userDetails, setUserDetails] = useState<any>(outcomeState.userDetails);

  const [trackQuoteData, setTrackQuoteData] = useState("");
  let userValues = JSON.parse(localStorage.getItem("userValues"));
  const hiddenFileInput = React.useRef(null);
  useEffect(() => {
    if (
      !userDetails ||
      !Object.keys(userDetails) ||
      !Object.keys(userDetails).length
    ) {
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomeState.userDetails]);
  useEffect(() => {
    if ((!notifications || !notifications.length) && !alreadyCalled) {
      notificationService
        .getNotificationList(outcomeState.token)
        .then((res) => {
          notificationDispatch(updateNotificationData(res?.notificationData));
          notificationDispatch(updateUnreadNotificationData(res?.unread));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // this.setState({
          //   buttonLoading: false
          // })
          // this.openCloseEditGoalModal(false)
        });
    }
  }, [alreadyCalled]);
  useEffect(() => {
    if (
      outcomeState?.journeyList &&
      outcomeState?.journeyList?.trackQuoteData &&
      outcomeState?.journeyList?.trackQuoteData[0] &&
      outcomeState?.journeyList?.trackQuoteData[0]?.title &&
      !trackQuoteData
    ) {
      setTrackQuoteData(outcomeState?.journeyList?.trackQuoteData[0]?.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomeState?.journeyList?.trackQuoteData]);
  const renderIcon = (
    icon,
    onClick = (val: any) => {},
    ref = null,
    customValue = undefined
  ) => (
    <div
      className={
        Number(customValue) >= 0 ? classes.iconRoundCustom : classes.iconRound
      }
      onClick={onClick}
      ref={ref}
    >
      {icon}{" "}
      {Number(customValue) >= 0 && (
        <Typography
          sx={{
            color: "#A0A0A1 !important",
            fontSize: "14px !important",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "100%",
          }}
        >
          {customValue}
        </Typography>
      )}
    </div>
  );
  const getUserDetails = () => {
    const userId = {
      uid: outcomeState?.userId
        ? outcomeState?.userId
        : userValues?.uid
        ? userValues?.uid
        : localStorage.getItem("userId"),
    };
    userService
      .getUserById(userId)
      .then((data: any) => {
        userTypeDispatch(setUserObject(data?.data));
        if (
          outcomeState.userDetails &&
          Object.keys(outcomeState.userDetails).length
        ) {
          setUserDetails(data?.data);
        }
        data?.data?.imageUrl !== "Not Updated"
          ? setImageFile(data?.data?.imageUrl)
          : setImageFile(null);
        localStorage.setItem("userValues", JSON.stringify(data?.data));
        localStorage.setItem("userDate", JSON.stringify(data?.data?.createdAt));
      })
      .catch((error) => {
        console.log("User profile Error");
      });
  };
  const gotoWooklyProgress = () => {
    reportTypeDispatch(setWeekReport(true));
    navigate("/progress/weekly");
  };
  const gotoArchiveMode = () => {
    // reportTypeDispatch(setWeekReport(true));
    navigate("/archive");
  };
  const popRef: any = useRef();
  const handleClickPopover = (event) => {
    if (popRef?.current) {
      notificationDispatch(
        setXPos(popRef?.current?.getBoundingClientRect().left)
      );
      notificationDispatch(
        setYPos(popRef?.current?.getBoundingClientRect().top + 40)
      );
      notificationDispatch(setAnchor(event.currentTarget));
    }
  };
  const userNamerender = (name = "") => {
    const arr = name.split(" ");
    const stringArrr = [];
    for (var i = 0; i < arr.length; i++) {
      stringArrr.push(arr[i].charAt(0).toUpperCase());
    }
    return stringArrr.join("");
  };
  return (
    <Grid
      container
      columns={16}
      sx={{
        flexWrap: "nowrap !important",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      className={classes.headerGrid}
    >
      <Grid
        item
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={"12px"}
        className={classes.headerGridChild}
      >
        <div className={classes.headerTiele}>
          <Typography
            sx={{
              color: "var(--color-black)",
              fontSize: "20px !important",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "100%",
            }}
          >
            Welcome back, {userDetails?.first_name}
          </Typography>
          <Typography
            id="track-quote"
            sx={{
              color: "var(--color-black)",
              fontSize: "14px !important",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "100%",
            }}
          >
            {trackQuoteData}
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        display={"flex"}
        gap={"16px"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        className={classes.headerGridChild6}
      >
        <div className={classes.icons}>
          <div
            style={{
              marginRight: "20px",
            }}
          >
            <TrackTitle
              setIsArchiveMode={false}
              isArchiveMode={false}
              createDate={outcomeState.userDetails.createdAt}
            />
          </div>

          {
            <HTMLTooltip
              customIcon={renderIcon(
                <StrikeIcon />,
                () => null,
                null,
                userDetails?.streakDays
              )}
              title="Strike"
              fontProps={{
                fontSize: `${pxToRem(12)} !important`,
              }}
              sx={{
                "& .MuiTooltip-tooltip": {
                  maxWidth: "300px",
                },
              }}
            />
          }
          <HTMLTooltip
            customIcon={renderIcon(<ArchiveIcon />, gotoArchiveMode)}
            title="Archive"
            fontProps={{
              fontSize: `${pxToRem(12)} !important`,
            }}
            sx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "300px",
              },
            }}
          />
          <HTMLTooltip
            customIcon={renderIcon(<Foldericon />, gotoWooklyProgress)}
            title="Reports"
            fontProps={{
              fontSize: `${pxToRem(12)} !important`,
            }}
            sx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "300px",
              },
            }}
          />
          <HTMLTooltip
            customIcon={renderIcon(
              <StyledBadge
                color={"primary"}
                badgeContent={unreadnotifications.length}
                max={99}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "var(--color-new-main)",
                    color: "var(--color-black)"
                  },
                }}
              >
                <BellIcon />
              </StyledBadge>,
              handleClickPopover,
              popRef
            )}
            title="Notifications"
            fontProps={{
              fontSize: `${pxToRem(12)} !important`,
            }}
            sx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "300px",
              },
            }}
          />
          {!xVersion && (
            <>
              <HTMLTooltip
                customIcon={renderIcon(<PlaneIcon />)}
                title="Vacation mode"
                fontProps={{
                  fontSize: `${pxToRem(12)} !important`,
                }}
                sx={{
                  "& .MuiTooltip-tooltip": {
                    maxWidth: "300px",
                  },
                }}
              />
            </>
          )}
          {userDetails.imageUrl && userDetails.imageUrl !== "Not Updated" ? (
            <img
              alt="user"
              src={
                userDetails.imageUrl
                  ? outcomeState?.outcomePopup?.isUploaded
                    ? URL.createObjectURL(outcomeState?.outcomePopup?.image)
                    : `${baseEndpoints.getImage}${userDetails.imageUrl}`
                  : DummyUser
              }
              style={{
                height: 32,
                width: 32,
                borderRadius: "44px",
                cursor: "pointer",
              }}
              onClick={() => navigate('/profile')}
            />
          ) : (
            <Avatar
              sx={{
                width: "32px",
                height: "32px",
                background: "var(--color-new-main)",
                fontSize: "16px",
                cursor: "pointer"
              }}
              onClick={() => navigate('/profile')}
            >
              {userNamerender(
                userDetails?.first_name + " " + userDetails?.last_name
              )}
            </Avatar>
          )}
        </div>
        {!xVersion && (
          <>
            <div className={classes.rectange}></div>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                padding: "15px 16px 15px 15px",
                alignItems: "center",
                gap: "6px",
                borderRadius: "100px",
                background: "#11734A",
                marginBottom: "0 !important",
              }}
              className={classes.button}
            >
              <ZapIcon />
              Upgrade
            </Button>
          </>
        )}
        <HeaderPopover
          handleClose={() => notificationDispatch(setAnchor(null))}
        />
      </Grid>
    </Grid>
  );
};

export default TrackHeader;
