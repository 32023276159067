import React, { useContext, useEffect, useState } from "react";
import { contactsService } from "../../../services/contactsService";
import { UserTypeContext } from "../../../context/userStatus";
import { Box, Grid, Typography } from "@mui/material";
import { pxToRem } from "../../../theme/typography";
import palette from "../../../theme/palette";
import NewButton from "../../../components/NewButton";
import { ReviewData } from "./contactsData";
import moment from "moment";
import RightIconSwitch from "../../../assets/images/icon/RightIconSwitch";
interface SocialReportSchedulesProps {
  socialReportsScedules: any[];
  viewSocialReports: React.Dispatch<React.SetStateAction<boolean>>;
  viewSceduleReports: boolean;
  allContacts?: any[];
  allDefaultStates: any[];
  classes: any;
  editRecord: any;
  setEditRecord: (any) => void;
}
const SocialReportSchedules = (props: SocialReportSchedulesProps) => {
  const { classes, editRecord, setEditRecord } = props;
  const [socialReportsList, setSocialReportsList] = useState([]);
  const [actionMode, setActionMode] = useState(null);
  const [actionSuccess, setActionSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  useEffect(() => {
    getReports(true);
  }, []);
  const getReports = (firstTimeLoad = false) => {
    if (firstTimeLoad) setIsLoading(true);
    contactsService
      .getAllSchedulesReports(IUserTypeState.token)
      .then((data) => {
        console.log("data", data);
        setIsLoading(false);
        if (data.body.length) {
          data.body = data.body.reverse()
          data.body = data.body.map((it, index) => {
            it.socialReportname = `Social Report #${data.body.length - index}`;
            return it;
          });
        }

        setSocialReportsList([...data.body]);
        // setShowSnakbar(true);
        // setMsgType("success");
        // setSnakbarMsg(data?.description);
      })
      .catch((err) => {
        if (err?.description) {
          //   setShowSnakbar(true);
          //   setMsgType("error");
          //   setSnakbarMsg(err?.description);
        }
      })
      .finally(() => {
        // setShowLoadingBtn(false)
      });
  };
  const handleUpdateStatus = (actionObject) => {
    contactsService
      .updateReportStatus(
        {
          shareReportId: editRecord.shareReportId,
          ...actionObject,
        },
        IUserTypeState.token
      )
      .then(() => {
        setActionSuccess(true);
        getReports();
      });
  };
  if (isLoading) {
    return null;
  }
  if (socialReportsList.length === 0) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              height: "365px",
              overflowY: "auto",
            }}
            className="hide-scrollbar"
          >
            <Typography
              sx={{
                color: palette.primary.light,
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "120% !important",
                // textAlign: "center !important",
                fontWeight: `400 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "100% !important",
                  },
                },
                margin: "0px 0px 12px",
              }}
            >
              There are no scheduled social reports
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <NewButton
                variant="text"
                buttonText={"Go Back"}
                textColor="var(--button-active-text)"
                onClick={() => {
                  props.viewSocialReports(!props.viewSceduleReports);
                }}
              />
              <NewButton
                variant="contained"
                buttonText={"Schedule"}
                onClick={() => {
                  props.viewSocialReports(!props.viewSceduleReports);
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
  console.log("editRecord", editRecord);
  console.log("actionMode", actionMode);

  const boxForReportDetails = (it, showActions = true) => {
    console.log("it", it);
    const isMonthly = ["LAST_THIRTY_DAYS", "LAST_MONTH"].includes(it.scheduleType)
    // const isWeekly = ["LAST_SEVEN_DAYS", "LAST_WEEK"].includes(it.scheduleType)
    return (
      <Box
        key={it.shareReportId}
        display={"flex"}
        alignItems={"flex-start"}
        flexDirection={"column"}
        gap={"12px"}
        marginTop={"21px"}
        width={"100%"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Typography
            sx={{
              color: palette.primary.light,
              fontSize: `${pxToRem(16)} !important`,
              lineHeight: "120% !important",
              // textAlign: "center !important",
              fontWeight: `700 !important`,
              "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                  fontSize: `${pxToRem(16)} !important`,
                  lineHeight: "100% !important",
                },
              },
            }}
          >
            {it.socialReportname}
          </Typography>
          {showActions && (
            <Box display={"flex"} alignItems={"center"} gap={"32px"}>
              <div
                onClick={() => {
                  // edit
                  setEditRecord(it);
                  props.viewSocialReports(!props.viewSceduleReports);
                }}
              >
                <Typography
                  sx={{
                    color: palette.secondary.contrast,
                    fontSize: `${pxToRem(14)} !important`,
                    lineHeight: "120% !important",
                    // textAlign: "center !important",
                    cursor: "pointer",
                    fontWeight: `400 !important`,
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(14)} !important`,
                        lineHeight: "100% !important",
                      },
                    },
                    ":hover": {
                      textShadow: "-1px 1px #00000040",
                      fontWeight: `500 !important`,
                    }
                  }}
                >
                  Edit
                </Typography>
              </div>
              <div
                onClick={() => {
                  // pause / Resume
                  setActionMode(it.sendStatus === "STOP" ? "resume" : "pause");
                  setEditRecord(it);
                }}
              >
                <Typography
                  sx={{
                    color: palette.secondary.contrast,
                    fontSize: `${pxToRem(14)} !important`,
                    lineHeight: "120% !important",
                    // textAlign: "center !important",
                    cursor: "pointer",
                    fontWeight: `400 !important`,
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(14)} !important`,
                        lineHeight: "100% !important",
                      },
                    },
                    ":hover": {
                      textShadow: "-1px 1px #00000040",
                      fontWeight: `500 !important`,
                    }
                  }}
                >
                  {it.sendStatus === "STOP" ? "Resume" : "Pause"}
                </Typography>
              </div>
              <div
                onClick={() => {
                  // delete
                  setActionMode("delete");
                  setEditRecord(it);
                }}
              >
                <Typography
                  sx={{
                    color: palette.secondary.contrast,
                    fontSize: `${pxToRem(14)} !important`,
                    lineHeight: "120% !important",
                    background: "#fff",
                    // background: 'rgba(155, 161, 157, 1)',
                    // textAlign: "center !important",
                    cursor: "pointer",
                    fontWeight: `400 !important`,
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(14)} !important`,
                        lineHeight: "100% !important",
                      },
                    },
                    ":hover": {
                      textShadow: "-1px 1px #00000040",
                      fontWeight: `500 !important`,
                    }
                  }}
                >
                  Delete
                </Typography>
              </div>
            </Box>
          )}
        </Box>
        <Box>
          <div style={{ marginBottom: "20px" }}>
            <span className={classes.reviewTitle}>Frequency:</span>
            <span className={classes.reviewDescription}>
              {isMonthly ? `Every month on ${it.dateToSend} at ` : `Every week on ${it.dayToSend.toLowerCase()} at `}
              {moment(it.timeToSend).format("HH:mm")}.
            </span>
          </div>
          {ReviewData(
            {
              contacts: props.allContacts[0].contacts.filter((ite) =>
                [...(it.contacts || [])]
                  .map((ite1) => ite1._id)
                  .includes(ite._id)
              ),
              outcomeList: props.allDefaultStates[0].items.filter((ite) =>
                [...(it.outcomeIds || [])].includes(ite.outcomeId)
              ),
              influenceList: props.allDefaultStates[1].items.filter((ite) =>
                [...(it.influenceIds || [])].includes(ite.influenceId)
              ),
            },
            true
          ).map((ite) => (
            <div style={{ marginBottom: "20px" }}>
              <span className={classes.reviewTitle}>
                {ite.title} ({ite.count}):
              </span>
              <span className={classes.reviewDescription}>
                {ite.description}
              </span>
            </div>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Grid container>
      {actionMode && editRecord && !actionSuccess && (
        <Box
          onClick={() => {
            setEditRecord(null);
            setActionMode(null);
            setActionSuccess(false);
          }}
          position={"absolute"}
          top={"48px"}
          right={"40px"}
        >
          <Typography
            sx={{
              color: palette.secondary.contrast,
              fontSize: `${pxToRem(16)} !important`,
              lineHeight: "120% !important",
              cursor: "pointer",
              // textAlign: "center !important",
              fontWeight: `400 !important`,
              "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                  fontSize: `${pxToRem(16)} !important`,
                  lineHeight: "100% !important",
                },
              },
            }}
          >
            Go back
          </Typography>
        </Box>
      )}
      <Grid item xs={12}>
        <Box
          display={"flex"}
          sx={{
            height: "365px",
            overflowY: "auto",
          }}
          flexDirection={"column"}
          className="hide-scrollbar"
        >
          {socialReportsList.length > 0 &&
            !actionMode &&
            !editRecord &&
            socialReportsList.map((it, index) => {
              return boxForReportDetails(it);
            })}
          {actionMode && editRecord && (
            <>
              {actionSuccess ? (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  gap={"16px"}
                >
                  <Typography
                    sx={{
                      color: palette.primary.light,
                      fontSize: `${pxToRem(22)} !important`,
                      lineHeight: "120% !important",
                      // textAlign: "center !important",
                      fontWeight: `500 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(22)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                  >
                    <RightIconSwitch color="var(--primary-color)" /> Social
                    report sucessfuly{" "}
                    {actionMode === "pause"
                      ? "paused"
                      : actionMode === "resume"
                      ? "resumed"
                      : "deleted"}
                    .
                  </Typography>
                  {boxForReportDetails(editRecord, false)}
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  gap={"6px"}
                >
                  <Typography
                    sx={{
                      color: palette.primary.light,
                      fontSize: `${pxToRem(22)} !important`,
                      lineHeight: "120% !important",
                      // textAlign: "center !important",
                      fontWeight: `500 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(22)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                  >
                    Are you sure you want to {actionMode} this report?
                  </Typography>
                  <Typography
                    sx={{
                      color: "#9BA19D",
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "120% !important",
                      // textAlign: "center !important",
                      fontWeight: `400 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                  >
                    {actionMode === "pause"
                      ? "It will be paused and can resume it again "
                      : actionMode === "resume"
                      ? "It will be resumed and can pause it again "
                      : "It will be permanently removed and can not be undone "}
                  </Typography>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {boxForReportDetails(editRecord, false)}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
        {actionMode && editRecord ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {actionSuccess ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <NewButton
                  variant="text"
                  buttonText={"Go back"}
                  textColor="var(--button-active-text)"
                  onClick={() => {
                    setEditRecord(null);
                    setActionMode(null);
                    setActionSuccess(false);
                  }}
                />
                <NewButton
                  variant="contained"
                  buttonText={"Schedule"}
                  onClick={() => {
                    setEditRecord(null);
                    setActionMode(null);
                    setActionSuccess(false);
                    props.viewSocialReports(!props.viewSceduleReports);
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                {actionMode === "pause" ? (
                  <NewButton
                    variant="contained"
                    buttonText={"Pause"}
                    onClick={() => {
                      handleUpdateStatus({
                        sendStatus:
                          actionMode === "resume" ? "REGULARLY" : "STOP",
                      });
                    }}
                  />
                ) : actionMode === "resume" ? (
                  <NewButton
                    variant="contained"
                    buttonText={"Resume"}
                    onClick={() => {
                      handleUpdateStatus({
                        sendStatus:
                          actionMode === "resume" ? "REGULARLY" : "STOP",
                      });
                    }}
                  />
                ) : (
                  <NewButton
                    variant="contained"
                    buttonText={"Delete"}
                    btnType="danger"
                    onClick={() => {
                      handleUpdateStatus({
                        deleteStatus: "ARCHIVE",
                      });
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <NewButton
                variant="text"
                buttonText={"Go Back"}
                textColor="var(--button-active-text)"
                onClick={() => {
                  props.viewSocialReports(!props.viewSceduleReports);
                  setEditRecord(null);
                }}
              />
              <NewButton
                variant="contained"
                buttonText={"Schedule"}
                onClick={() => {
                  props.viewSocialReports(!props.viewSceduleReports);
                }}
              />
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default SocialReportSchedules;
