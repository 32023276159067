import { Box } from "@mui/material"
import CommonTypo from "../../../components/CommonTypo"
import React from "react";

const HighestEntryOn = ({
    specificInflunceData
}) => {
    const mostEntry = [{
        day: "Mon",
        achive: 20,
        rating: 2.1
    }, {
        day: "Tue",
        achive: 20,
        rating: 4.1
    }, {
        day: "Wed",
        achive: 20,
        rating: 3.3
    }, {
        day: "Thu",
        achive: 20,
        rating: 2.0
    }, {
        day: "Fri",
        achive: 20,
        rating: 2.5
    }, {
        day: "Sat",
        achive: 20,
        rating: 1.9
    }, {
        day: "Sun",
        achive: 20,
        rating: 4.2
    }]

    for (let index = 0; index < specificInflunceData.length; index++) {
        const element = specificInflunceData[index];
        if (element._id && element._id.day && element._id.day.slice(0, 3)) {
            const found = mostEntry.findIndex(it => it.day.toLowerCase() === element._id.day.slice(0, 3).toLowerCase())
            if (found > -1) {
                mostEntry[found].rating = element.count
            }
        }
    }
    const top =  [...mostEntry]
    .map(it => it.rating)   // Extract ratings
    .sort((a, b) => b - a)   // Sort in descending order
    .slice(0, 2) 
    let successCount = top[1] ? top[1] : top[0];  
    return <Box sx={{
        padding: "24px",
        border: "1px solid #EEEEEE",
        display: "flex",
        borderRadius: "12px",
        flexDirection: "column",
        gap: "8px",
        height: "184px",
        width: "563px",
        justifyContent: "space-between"
    }}>
        <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
            whiteSpace: "nowrap",
        }}>{"Highest entries on"}</CommonTypo>
        <Box sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: "16px",
            height: "100%"
        }}>
            {mostEntry.map(it => <DayRender rating={it.rating} day={it.day} success={it.rating >= successCount} />)}
        </Box>
    </Box>
}

export default HighestEntryOn

export const DayRender = ({ day, rating, success }) => {
    const percentage = (rating * 100) / 7
    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "center",
        gap: "8px"
    }}>
        <Box sx={{
            background: success ? "var(--color-new-main)" : "#EEEEEE",
            borderRadius: "8px",
            width: "59.86px",
            height: `calc(${percentage}%)`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingBottom: "4px"
        }}>
            <CommonTypo size={16} color="#656565" fontWeight={success ? 600 : 400}>{rating}</CommonTypo>
        </Box>
        <CommonTypo size={16} color="#000000" fontWeight={success ? 600 : 400}>{day}</CommonTypo>
    </Box>
}