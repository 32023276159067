/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
  FormControl,
} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useFormik } from "formik";
import clsx from "clsx";
import * as yup from "yup";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { styled } from "@mui/material/styles";

// import palette from "../../theme/palette";
// import ProgressStepper from "./progressStepper";
import ModalCloseIcon from "../../../assets/images/icon/modalCloseIconBlack";
import { ContactsDataJson as contacts, ReviewData } from "./contactsData";
import { ButtonLoader } from "../../../components/buttonLoader";
import palette from "../../../theme/palette";
import { ContactsContext } from "../../../context/userIContacts";
import NewButton from "../../../components/NewButton";
// import { trackingService } from "../../services/tracking";
// import { UserTypeContext } from "../../context/userStatus";
// import { ButtonLoader } from "../../components/buttonLoader";
// import OutcomeclosePopup from "./OutcomeclosePopup";
// import DeleteIcon from "../../assets/images/icon/DeleteIcon";
// import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});
const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    marginBottom: "16px!important",
  },
  raioOptionInputs: {
    "& .MuiOutlinedInput-root": {
      height: "48px",

      /* BackgroundColors/GreenyAreaBG */

      /* BackgroundColors/Dividers */

      border: "1px solid #DCE6E0",
      borderRadius: "8px",
      padding: "0 4px",
      margin: "auto",
      "& input": {
        padding: "2px !important",
      },
    },
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& button:nth-child(2)": {
      marginLeft: "24px",
    },
  },
  namegroupDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "16px 0",
  },
  nameEmailDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  groupDivNameText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "#3E4240",
    margin: "4px 0",
  },
  nameSelection: {
    display: "flex",
  },
  groupDivEmailText: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "#9BA19D",
    margin: "4px 0",
  },
  radioButton: {
    "& .Mui-checked": {
      color: palette.primary.main,
    },
  },
}));
interface AddContact {
  editData?: any;
  visibleAddContactModal: boolean;
  setVisibleAddContactModal: (val: boolean) => void;
  handleAddContact: (val: any, update: any) => void;
  handleAddGroup?: (val: any, update: any) => void;
  showLoadingBtn: boolean;
  setShowLoadingBtn: (val: boolean) => void;
  updateGroupInfo?: any;
  selectedAnchor?: any;
  isAddInGroup?: boolean;
}

const AddContactModal = (props: AddContact) => {
  const classes = useStyles();
  const { state: IContactTypeState, dispatch: contactsDispatch } =
    useContext(ContactsContext);
  const [isOwn, setIsOwn] = useState("own");
  const [allContacts, setAllContacts] = useState(
    IContactTypeState?.allContacts
  );
  const [name, setName] = useState(props.editData?.name);
  const [email, setEmail] = useState(props.editData?.email);
  const [contactsData, setContactsData] = useState(contacts);
  const [radioValue, setRadioValue] = useState("immediatly");
  // const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const { showLoadingBtn, setShowLoadingBtn } = props;
  const [outcomeNotes, setOutcomeNotes] = useState<any>("");
  const [item, setItem] = useState<any>();
  const [openOutcomeclosePopup, setopenOutcomeclosePopup] = useState(false);
  const [openOutcomeDeletePopup, setopenOutcomeDeletePopup] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isValidEmail, setIsValidemail] = useState(false);

  const setContactChecked = (checked, contact) => {
    const allContactData = [...allContacts];
    const foundContact = allContactData.findIndex(
      (it) => it._id === contact._id
    );
    if (foundContact > -1) {
      allContactData[foundContact].checked = checked;
      setAllContacts(allContactData);
    }
  };

  useEffect(() => {
    if (
      props?.updateGroupInfo &&
      props?.updateGroupInfo?.contacts &&
      props?.updateGroupInfo?.contacts?.length
    ) {
      const filteredArr = [...allContacts].filter((it) => {
        const foundContact = [...props?.updateGroupInfo?.contacts].find(
          (ite) => ite.email === it.email
        );
        return !foundContact;
      });
      setAllContacts(filteredArr);
    }
    // if(props.editData?.name) {
    //   formik.setFieldValue('name', props.editData?.name, true)
    //   formik.setFieldValue('email', props.editData?.email, true)
    //   formik.validateOnMount = true
    // }
    // if(props.editData?.email) {
    // }
    return () => {
      setShowLoadingBtn(false);
      setIsOwn("own");
    };
  }, []);
  const ValidateEmail = (input) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input)) {
      return true;
    } else {
      return false;
    }
  };
  const formik = useFormik({
    initialValues: {
      name: (props.editData?.name as string) || "",
      email: (props.editData?.email as string) || "",
    },
    isInitialValid:
      props.editData && Object.keys(props.editData).length ? true : false,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // setShowLoader(true);
      // handleJoinWaitlist({
      //   "user_email": values.email,
      //   "name": values.name
      // })
    },
  });
  // useEffect(() => {
  //   if (email) {
  //     const validatEmail = ValidateEmail(email)
  //     console.log("email.length", validatEmail);
  //     if (!validatEmail) {
  //       setIsValidemail(false)
  //       setErrors({
  //         ...errors,
  //         email: "Please enter proper email"
  //       })
  //     } else {
  //       setIsValidemail(true)
  //       setErrors({
  //         ...errors,
  //         email: ""
  //       })
  //     }
  //   } else {
  //     setIsValidemail(false)
  //     setErrors({
  //       ...errors,
  //       email: ""
  //     })
  //   }
  // }, [email])

  return (
    <Grid>
      <Dialog
        onClose={() => props.setVisibleAddContactModal(false)}
        open={props.visibleAddContactModal}
        className="dialogWrapper dialogWrapper-addContact dialogWrapper-mobileScreen"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            paddingBottom: "10px",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="span">
            {props?.editData && Object.keys(props?.editData).length
              ? "Update"
              : "Add"}{" "}
            Contact{" "}
            {props?.isAddInGroup &&
            props?.updateGroupInfo &&
            props?.updateGroupInfo?.groupName
              ? ` To ${props?.updateGroupInfo.groupName}`
              : ""}
          </Typography>

          {/* <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            // onClick={handleCloseStartModal}
            onClick={() => props.handleShareModalOpen(false)}
          >
            <ModalCloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          {props?.isAddInGroup &&
          props?.updateGroupInfo &&
          props?.updateGroupInfo?.groupName &&
          !Object.keys(props?.editData || {}).length ? (
            <FormControl>
              <RadioGroup
                className="radio-wrapper"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e) => setIsOwn(e.target.value)}
                value={isOwn || ""}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  className={classes.radioButton}
                  label={"From All Contacts"}
                />
                <FormControlLabel
                  value="own"
                  control={<Radio />}
                  className={classes.radioButton}
                  label={"Add Custom"}
                />
              </RadioGroup>
            </FormControl>
          ) : null}
          {isOwn === "all" ? (
            <div style={{ marginTop: "20px" }}>
              {allContacts.map((it) => {
                return (
                  <div className={classes.nameSelection}>
                    <CustomCheckBox
                      checked={it.checked}
                      onChange={(e) => {
                        setContactChecked(e.target.checked, it);
                      }}
                    />
                    <div className={classes.namegroupDiv}>
                      <div className={classes.nameEmailDiv}>
                        <span className={clsx(classes.groupDivNameText)}>
                          {it.name}
                        </span>
                        <span className={clsx(classes.groupDivEmailText)}>
                          {it.email}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div style={{ marginTop: "20px" }}>
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.pageTitle}
                >
                  Name
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="name"
                  placeholder="Add Name"
                  className={classes.raioOptionInputs}
                  onChange={(e) => {
                    setName(e.target.value);
                    formik.handleChange(e);
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.pageTitle}
                >
                  Email
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="email"
                  placeholder="Add Email"
                  className={classes.raioOptionInputs}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  value={formik.values.email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Box className={classes.bottomButton}>
            <NewButton
              buttonText="Cancel"
              onClick={() => props.setVisibleAddContactModal(false)}
              variant="outlined"
            />
            <NewButton
              buttonText={
                props?.editData && Object.keys(props?.editData).length
                  ? "Update"
                  : "Add"
              }
              isLoadong={showLoadingBtn}
              onClick={() => {
                setShowLoadingBtn(true);
                if (isOwn === "all") {
                  props.handleAddGroup(
                    {
                      ...props?.updateGroupInfo,
                      contacts: [
                        ...props?.updateGroupInfo?.contacts,
                        ...allContacts
                          .filter((it) => it.checked)
                          .map((it) => ({
                            name: it.name,
                            email: it.email,
                          })),
                      ],
                      id: { ...(props?.updateGroupInfo || {}) }?._id,
                    },
                    { ...(props?.updateGroupInfo || {}) }?._id
                  );
                } else {
                  props.handleAddContact(
                    {
                      ...formik.values,
                      id: props.editData?._id,
                    },
                    props.editData?._id
                  );
                }
              }}
              variant="contained"
              type="submit"
              isDisabled={
                isOwn === "all"
                  ? !(allContacts.filter((it) => it.checked).length > 0)
                  : !formik.isValid
              }
            />
          </Box>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default AddContactModal;
