import { Box } from "@mui/material"
import React from "react"
import CommonTypo from "../../../components/CommonTypo"

const AvgRating = ({
    rating = 0
}) => {
    return <Box sx={{
        padding: "24px",
        border: "1px solid #EEEEEE",
        display: "flex",
        borderRadius: "12px",
        flexDirection: "column",
        gap: "8px",
        height: "184px",
        width: "180px",
        justifyContent: "space-between",
        position: "relative"
    }}>
        <Box sx={{
            position: "absolute",
            right: 0,
            top: 0,
            left: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        }}>
            <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
                whiteSpace: "nowrap",
            }}>{rating.toString()}</CommonTypo>
            <CommonTypo size={16} lineHeight={16} color="#3E4240" fontWeight={400} extraSx={{
                whiteSpace: "pre-wrap",
                textAlign: "center"
            }}>{"Avg. \n Rating"}</CommonTypo>
        </Box>
        <svg width="132" height="132" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_1127_370" fill="white">
                <path d="M66 9.9C66 4.43238 70.4569 -0.0760153 75.8631 0.741089C85.4125 2.1844 94.5707 5.71278 102.668 11.123C113.521 18.3752 121.981 28.683 126.976 40.7429C131.971 52.8028 133.278 66.0732 130.732 78.876C128.185 91.6787 121.899 103.439 112.669 112.669C103.439 121.899 91.6787 128.185 78.876 130.732C66.0732 133.278 52.8028 131.971 40.7429 126.976C28.683 121.981 18.3752 113.521 11.123 102.668C5.71278 94.5707 2.1844 85.4125 0.741089 75.8631C-0.0760193 70.4569 4.43238 66 9.9 66C15.3676 66 19.6939 70.482 20.8567 75.8246C22.0815 81.4522 24.3541 86.8303 27.5861 91.6673C32.6626 99.2649 39.8781 105.186 48.32 108.683C56.762 112.18 66.0513 113.095 75.0132 111.312C83.9751 109.53 92.2071 105.13 98.6683 98.6683C105.13 92.2071 109.53 83.9751 111.312 75.0132C113.095 66.0512 112.18 56.762 108.683 48.32C105.186 39.8781 99.2649 32.6626 91.6673 27.5861C86.8303 24.3541 81.4522 22.0815 75.8246 20.8567C70.482 19.6939 66 15.3676 66 9.9Z" />
            </mask>
            <path d="M66 9.9C66 4.43238 70.4569 -0.0760153 75.8631 0.741089C85.4125 2.1844 94.5707 5.71278 102.668 11.123C113.521 18.3752 121.981 28.683 126.976 40.7429C131.971 52.8028 133.278 66.0732 130.732 78.876C128.185 91.6787 121.899 103.439 112.669 112.669C103.439 121.899 91.6787 128.185 78.876 130.732C66.0732 133.278 52.8028 131.971 40.7429 126.976C28.683 121.981 18.3752 113.521 11.123 102.668C5.71278 94.5707 2.1844 85.4125 0.741089 75.8631C-0.0760193 70.4569 4.43238 66 9.9 66C15.3676 66 19.6939 70.482 20.8567 75.8246C22.0815 81.4522 24.3541 86.8303 27.5861 91.6673C32.6626 99.2649 39.8781 105.186 48.32 108.683C56.762 112.18 66.0513 113.095 75.0132 111.312C83.9751 109.53 92.2071 105.13 98.6683 98.6683C105.13 92.2071 109.53 83.9751 111.312 75.0132C113.095 66.0512 112.18 56.762 108.683 48.32C105.186 39.8781 99.2649 32.6626 91.6673 27.5861C86.8303 24.3541 81.4522 22.0815 75.8246 20.8567C70.482 19.6939 66 15.3676 66 9.9Z" stroke="#ADE888" stroke-width="20" stroke-linejoin="round" mask="url(#path-1-inside-1_1127_370)" />
        </svg>

    </Box>
}

export default AvgRating