import React from "react"

const BellIcon = ({ isMobile = false }) => {
  if (isMobile) {
    return <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.333 8.88542C20.333 7.11731 19.6307 5.42161 18.3804 4.17137C17.1302 2.92113 15.4345 2.21875 13.6664 2.21875C11.8983 2.21875 10.2026 2.92113 8.95234 4.17137C7.70209 5.42161 6.99972 7.11731 6.99972 8.88542C6.99972 16.6632 3.66638 18.8854 3.66638 18.8854H23.6664C23.6664 18.8854 20.333 16.6632 20.333 8.88542Z" stroke="#5E5F5E" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.5948 23.3335C15.3995 23.6702 15.1191 23.9498 14.7817 24.1441C14.4444 24.3384 14.0619 24.4407 13.6726 24.4407C13.2833 24.4407 12.9008 24.3384 12.5634 24.1441C12.2261 23.9498 11.9457 23.6702 11.7504 23.3335" stroke="#5E5F5E" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  }
  return <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "27" : "20"} height={isMobile ? "27" : "20"} viewBox="0 0 20 20" fill="none">
    <path d="M15 6.66406C15 5.33798 14.4732 4.06621 13.5355 3.12853C12.5979 2.19085 11.3261 1.66406 10 1.66406C8.67392 1.66406 7.40215 2.19085 6.46447 3.12853C5.52678 4.06621 5 5.33798 5 6.66406C5 12.4974 2.5 14.1641 2.5 14.1641H17.5C17.5 14.1641 15 12.4974 15 6.66406Z" stroke="#A0A0A1" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.4419 17.5C11.2954 17.7526 11.0851 17.9622 10.8321 18.1079C10.5791 18.2537 10.2922 18.3304 10.0003 18.3304C9.70828 18.3304 9.42142 18.2537 9.1684 18.1079C8.91539 17.9622 8.7051 17.7526 8.55859 17.5" stroke="#A0A0A1" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
}

export default BellIcon