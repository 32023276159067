const prod = {
  url: {
    // API_URL: "http://82.180.160.159:3002",
    // API_URL: "http://92.205.28.214:3002",
    // API_URL: "http://82.180.160.159:3020",
    API_URL: "https://api.tenacious-tracker.com",
  },
  x_version: true
};
const dev = {
  url: {
    // API_URL: "https://api.tenacious-tracker.com", // LIVE WITH DOMAIN
    // API_URL: "http://92.205.28.214:3002",
    API_URL: "http://82.180.160.159:3020", // LIVE WITH IP
    // API_URL: "http://82.180.160.159:3002",
  },
  x_version: false

};
const { x_version } = window as any
export const newOnboarding = true
export const config = process.env.NODE_ENV === "development" ? dev : prod;
export const xVersion = x_version as any;
export const baseEndpoints = {
  createUser: `${config.url.API_URL}/api/v1/app/create-user`,
  passcodeVerify: `${config.url.API_URL}/api/v1/passcode/verify`,
  userLogin: `${config.url.API_URL}/api/v1/app/user-login`,
  emailVerify: `${config.url.API_URL}/api/v1/app/email-verify`,
  addLevel: `${config.url.API_URL}/api/v1/app/add-level`,
  outcome: `${config.url.API_URL}/api/v1/outcome`,
  outcomeSearch: `${config.url.API_URL}/api/v1/outcome-suggestion/search`,
  outcomeSuggestion: `${config.url.API_URL}/api/v1/outcome-suggestion`,
  influence: `${config.url.API_URL}/api/v1/influence`,
  notification: `${config.url.API_URL}/api/v1/notification`,
  influenceSearch: `${config.url.API_URL}/api/v1/influence-suggestion/search`,
  influenceSuggestion: `${config.url.API_URL}/api/v1/influence-suggestion`,
  reflective: `${config.url.API_URL}/api/v1/reflective`,
  reflectiveSearch: `${config.url.API_URL}/api/v1/reflective-suggestion/search`,
  signupcompleted: `${config.url.API_URL}/api/v1/app/mixpanel/events-track`,
  reflectiveSuggestion: `${config.url.API_URL}/api/v1/reflective-suggestion`,
  trackingOutcome: `${config.url.API_URL}/api/v1/outcome-response`,
  trackingInfluence: `${config.url.API_URL}/api/v1/influence-response`,
  journey: `${config.url.API_URL}/api/v1/journey`,
  tracking: `${config.url.API_URL}/api/v1`,
  userProfile: `${config.url.API_URL}/api/v1/app`,
  category: `${config.url.API_URL}/api/v1/category`,
  categorySuggestion: `${config.url.API_URL}/api/v1/category-suggestion`,

  getImage: `${config.url.API_URL}/api/v1/app/read-file`,
  getFaq: `${config.url.API_URL}/api/v1/faq`,
  feature: `${config.url.API_URL}/api/v1/feature`,
  bug: `${config.url.API_URL}/api/v1/bug`,
  feedback: `${config.url.API_URL}/api/v1/feedback`,

  //reminder
  reminderCreate: `${config.url.API_URL}/api/v1/reminder/create`,
  reminderList: `${config.url.API_URL}/api/v1/reminder/list`,
  

  //setuser
  setProfileImage: `${config.url.API_URL}/api/v1/app/update-profile-image`,
  setUserInfo: `${config.url.API_URL}/api/v1/app/update-profile`,

  //setgoals
  outComeGoals: `${config.url.API_URL}/api/v1/outcome-goal/get`,
  goalsMenu: `${config.url.API_URL}/api/v1/journey/get-goals-menu`,
  influenceGoal: `${config.url.API_URL}/api/v1/influence-goal/get`,
  //create api
  outComeGoalsCreate: `${config.url.API_URL}/api/v1/outcome-goal/create`,
  influenceGoalsCreate: `${config.url.API_URL}/api/v1/influence-goal/create`,
  // scalerData: `${config.url.API_URL}/api/v1/vv-goal/create`,
  reflectiveGoal: `${config.url.API_URL}/api/v1/reflective-goal/get`,
  getReflactiveQuestion: `${config.url.API_URL}/api/v1/reflective/get`,
  createReflactiveQuestionGole: `${config.url.API_URL}/api/v1/reflective-goal/create`,
  updateOutcomeData: `${config.url.API_URL}/api/v1/outcome-goal/update`,
  updateInfluenceData: `${config.url.API_URL}/api/v1/influence-goal/update`,
  updateReflactiveGole: `${config.url.API_URL}/api/v1/reflective-goal/update`,
  outcomeToInfluece: `${config.url.API_URL}/api/v1/versioning/outcome-to-influence`,
  InflueceToOutcome: `${config.url.API_URL}/api/v1/versioning/influence-to-outcome`,
  InflueceToInfluence: `${config.url.API_URL}/api/v1/versioning/influence-to-influence`,

  // Archive APi's
  getArchiveData: `${config.url.API_URL}/api/v1/journey/get-archive`,
  archiveOutComeData: `${config.url.API_URL}/api/v1/outcome/archive`,
  archiveInfluanceData: `${config.url.API_URL}/api/v1/influence/archive`,
  archiveReflectiveData: `${config.url.API_URL}/api/v1/reflective/archive`,
  deleteArchiveOutComeData: `${config.url.API_URL}/api/v1/outcome/delete`,
  deleteArchiveInfluanceData: `${config.url.API_URL}/api/v1/influence/delete`,
  deleteArchiveReflectiveData: `${config.url.API_URL}/api/v1/reflective/delete`,
  uploadData: `${config.url.API_URL}/api/v1/influence-response/provision-response`,
  exportData: `${config.url.API_URL}/api/v1/influence-response/export`,
  importData: `${config.url.API_URL}/api/v1/influence-response/provision-response`,

  // waitlist
  waitlist_join: `${config.url.API_URL}/api/v1/wait-list/create`,

  // insights
  insightList: `${config.url.API_URL}/api/v1/journey/get-outcome-influence-insights`,
  trendData: `${config.url.API_URL}/api/v1/journey/get-outcome-response-trend`,
  influencetrendData: `${config.url.API_URL}/api/v1/journey/get-influence-response-trend`,
  influenceInsights: `${config.url.API_URL}/api/v1/journey/get-influence-outcome-insights`,
  INSIGHTS: {
    CORRELATIno_MENU: `${config.url.API_URL}/api/v1/journey/get-correlation-menu`,
    CORRELATIS: `${config.url.API_URL}/api/v1/journey/get-correlation`,
  },
  reports: {
    getAllWeek: `${config.url.API_URL}/api/v1/report/get-all-weeks`,
    getAllWeekReport: `${config.url.API_URL}/api/v1/report/get-week-report`,
  },
  GROUP_CONTACTS: {
    LIST_GROUP: `${config.url.API_URL}/api/v1/group-contact/list`,
    CONTACT_CREATE: `${config.url.API_URL}/api/v1/contact/create`,
    CONTACT_UPDATE: `${config.url.API_URL}/api/v1/contact/update`,
    CONTACT_DELETE: `${config.url.API_URL}/api/v1/contact/delete`,
    GROUP_CREATE: `${config.url.API_URL}/api/v1/group-contact/create`,
    GROUP_UPDATE: `${config.url.API_URL}/api/v1/group-contact/update`,
    GROUP_DELETE: `${config.url.API_URL}/api/v1/group-contact/delete`,
    SHARE_REPORT: `${config.url.API_URL}/api/v1/report/share-report`,
    VIEW_SHARE_REPORT: `${config.url.API_URL}/api/v1/report/view-share-report`,
    GET_REPORT_DETAILS: `${config.url.API_URL}/api/v1/report/get-record-id`,
    GET_REPORTS: `${config.url.API_URL}/api/v1/report/get-records`,
    UPDATE_RECORDS: `${config.url.API_URL}/api/v1/report/update-record`,
  }
};
