import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import { TrackingContext } from "../../context/userTracking";
import SideBar from "../../components/SideBar";
import TrackHeader from "../tracker/TrackHeader";
import TrackingModal from "./trakingModal";
import { makeStyles } from "@material-ui/core";
import {
  setArchiveMode,
  setSidebarExpand,
} from "../../store/actions/trackerAction";
import TrackTitle, { TrackArchiveTitle } from "../tracker/TrackTitle";
import { UserTypeContext } from "../../context/userStatus";
import ArchiveItemTable from "../tracker/ArchiveItemTable";
import TrackBodyData from "./trackBodyData";
import AddCategoryModal from "./AddCategoryModal";
const useStyles = makeStyles((theme) => ({
  trackBodyGrid: {
    display: "flex",
    backgroundColor: "white",
    zIndex: 1,
    flexShrink: 0,
    height: "100vh",
    overflow: "auto",
  },
  modalCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  },
  drawerTitle: {
    display: "flex",
    padding: "20px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderBottom: "1px solid #F3F3F3",
    background: "#FFF",
  },
  drawerBody: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  headerTitle: {
    display: "flex",
    padding: "24px 32px",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: "1px solid #F3F3F3",
    background: "#FFF",
    justifyContent: "space-between",
  },
}));
const DesktopView = () => {
  const classes = useStyles();
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  
  const { isExpandable, isArchiveMode } = ITrackerState;
  const setIsArchiveMode = (val) => {
    // trackingDispatch(setArchiveMode(val))
  };
  return (
    <Grid
      container
      columns={16}
      // height={"100vh"}
      sx={{ /* maxHeight: '100vh', */ overflow: "hidden" }}
      className="tracking_container"
      // style={{
      //   height: '100vh !important'
      // }}
    >
      {/* FIRST TIME TRACKING MODAL */}

      <TrackingModal />
      {/* START LEFT SIDEBAR */}
      <Grid
        item
        xs={/* IUserTypeState?.isExpand ||  */ isExpandable ? 0.72 : 2.04}
        sx={{ borderRight: "1px solid #DCE6E0", height: "100vh" }}
      >
        <SideBar
          isExpandMode={isExpandable}
          setIsExpandMode={(val) => {
            trackingDispatch(setSidebarExpand(val));
            // userTypeDispatch(isExpandMode(val))
          }}
        />
      </Grid>
      {/* END SIDEBAR */}

      {/* START CONTENT SECTION */}
      <Grid
        item
        xs={isExpandable ? 15.27 : 13.96}
        sx={{
          flexDirection: "column",
          background: "transparent !important",
          height: "100vh",
        }}
        className={classes.trackBodyGrid}
      >
        <TrackHeader setIsArchiveMode={setIsArchiveMode} />
        <Box
          sx={{
            borderRadius: "20px",
            background: "transparent",
            margin: "32px 0px 0px",
            height: "100%",
          }}
        >
          {isArchiveMode ? (
            <TrackArchiveTitle
              setIsArchiveMode={setIsArchiveMode}
              isArchiveMode={isArchiveMode}
            />
          ) : null}
          {/* CALENDER VIEW */}

          {!isArchiveMode ? <TrackBodyData /> : <ArchiveItemTable />}
        </Box>
        {/* PAGE HEADING */}

        {/* <ArchiveItemTable /> */}
        <AddCategoryModal />
      </Grid>
      {/* END CONTENT SECTION */}
    </Grid>
  );
};

export default DesktopView;
