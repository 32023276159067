import { Box, Grid, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../components/SideBar";
import ProgressTitle from "./progressTitle";
import ProgressOutcomes from "./progressOutcomes";
import ProgressInfluences from "./progressInfluences";
import RatingGraph from "./ratingGraph";
import { makeStyles } from "@material-ui/core";
import { ReportsTypeContext } from "../../context/userIReports";
import { UserTypeContext } from "../../context/userStatus";
import { reportsService } from "../../services/reportsService";
import {
  setReportWeekData,
  setReportWeekDataTotalCount,
} from "../../store/actions/reportTypeAction";
import FullpageLoader from "../../components/fullPageLoader";
import ProgressTitleDetails from "./progressTitleDetails";
import ReportDetails from "./ReportDetails";
import TrackHeader from "../tracker/TrackHeader";
import { useNavigate } from "react-router-dom";
import { ContactsContext } from "../../context/userIContacts";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "0px!important",
  overflowX: "hidden",
  overflowY: "auto",
  [theme.breakpoints.down("lg")]: {
    padding: "40px 20px 0px",
  },
  [theme.breakpoints.down(1001)]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  progressInfluence: {
    [theme.breakpoints.down(1001)]: {
      maxWidth: "100%!important",
    },
  },
  ratingGraph: {
    [theme.breakpoints.down(1001)]: {
      maxWidth: "100%!important",
      marginTop: "48px!important",
    },
  },
}));

const ProgressWeek = () => {
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { state: IContactTypeState, dispatch: contactsDispatch } =
      useContext(ContactsContext);
  const { state: IReportTypeContext, dispatch: reportTypeDispatch } =
    useContext(ReportsTypeContext);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getUserWeekReport(IReportTypeContext.currentPage);
  }, [IReportTypeContext.isWeekReport, IReportTypeContext.currentPage]);
  const getUserWeekReport = (page) => {
    setShowLoader(true);
    const userId = {
      page,
    };
    reportsService
      .getAllWeeks(userId, IUserTypeState.token)
      .then((data: any) => {
        reportTypeDispatch(setReportWeekData(data?.data));
        reportTypeDispatch(setReportWeekDataTotalCount(data?.total));
      })
      .catch((error) => {
        console.log("User profile Error");
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  const classes = useStyles();
  const [isExpandMode, setIsExpandMode] = useState(
    localStorage.getItem("isExpandMode") === "true"
  );
  console.log();
  
  return (
    <Grid
      container
      columns={16}
      height={"100vh"}
      className="tracking_container"
    >
      {showLoader && <FullpageLoader />}
      <Grid
        item
        sx={{ borderRight: "1px solid #DCE6E0" }}
        xs={isExpandMode ? 0.73 : 2.04}
      >
        <SideBar
          isExpandMode={isExpandMode}
          setIsExpandMode={setIsExpandMode}
        />
      </Grid>

      <Grid item xs={isExpandMode ? 15.27 : 13.96}>
        <RootStyle>
          <TrackHeader
            setIsArchiveMode={(val) => {
              if (val) {
                navigate("/tracking", {
                  state: {
                    direct: true,
                  },
                });
              }
            }}
          />
          {Object.keys(IReportTypeContext.selectedRow).length && window.location.pathname.includes("progress/weekly") && !IContactTypeState.shareModalOpen ? (
            <Box
              sx={{
                background: Object.keys(IReportTypeContext.selectedRow).length
                  ? "transparent"
                  : "#fff",
                margin: "32px",
                padding: "0!important",
                height: "calc(100vh - 150px) !important",
                overflowY: "auto",
                overflowX: "hidden"
              }}
              className="hide-scrollbar"
            >
              <ProgressTitleDetails setShowLoader={setShowLoader} />
              <ReportDetails />
            </Box>
          ) : (
            <Box
              sx={{
                background: Object.keys(IReportTypeContext.selectedRow).length
                  ? "transparent"
                  : "#fff",
                margin: "32px",
                padding: "24px",
                borderRadius: "20px 20px 0px 0px",
              }}
            >
              <ProgressTitle />
              <ProgressOutcomes />
            </Box>
          )}
        </RootStyle>
      </Grid>
    </Grid>
  );
};

export default ProgressWeek;
