
import React from 'react'

export default function ArrowDownNew() {
  return (
    <>
      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.41 7.70508L6 3.12508L10.59 7.70508L12 6.29508L6 0.295078L1.23266e-07 6.29508L1.41 7.70508Z" fill="#B8D3C0" />
      </svg>

    </>
  )
}

export const ArrowUpNew = ({ color = "#B8D3C0" }) => {
  return <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.59 1.85161e-06L6 4.58L1.41 2.46532e-07L-2.46532e-07 1.41L6 7.41L12 1.41L10.59 1.85161e-06Z" fill={color} />
  </svg>

}