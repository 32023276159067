import React, { useContext, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import { Card, Paper, styled, Typography } from "@mui/material";
import { SidebarBottomItems, SideBarItems } from "../utils/staticDataJson";
import palette from "../theme/palette";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { UserTypeContext } from "../context/userStatus";
import { userService } from "../services/auth";
import { categoryService } from "../services/category";
import { getActive } from "../utils/getActiveRoute";
import { baseEndpoints } from "../services/config";
import { addCategoryExpandAction, addCategoryJourneyAction, addInfluenceAction, addJourneyAction, addNotesAction, addNotesJourneyAction, addOutcomeAction, addRefelectiveAction, isPersonalMode } from "../store/actions/userTypeAction";
import { ContactsContext } from "../context/userIContacts";
import { setShareModalOpen } from "../store/actions/contactsActions";
import { setTrackingState } from "../store/actions/trackerAction";
import { TrackingContext } from "../context/userTracking";

const RootStyle = styled("div")(({ theme }) => ({
  // backgroundColor: '#F7F9FA',
  // position: "relative",
  // height: "calc(100vh - 5%)",
  display: 'flex',
  width: '100%',
  height: '100vh',
  padding: '24px 0px 20px 20px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  flexShrink: 0,
  position: 'relative'
}));
const SideBarItem = styled("div")(({ theme }) => ({
  width: "100%",
  // height: "2rem",
  margin: '10px 0',
  padding: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  cursor: "pointer",
  // border: '1px solid black',
  [theme.breakpoints.up("xl")]: {},
  [theme.breakpoints.up("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const MenuItems = styled(Typography)(({ theme }) => ({
  marginLeft: "8px",
  // border: '1px solid black',
  [theme.breakpoints.up("xl")]: {},
  [theme.breakpoints.up("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const useStyles = makeStyles((theme) => ({
  userCardNormal: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "40px 0px 40px 0px",
    padding: "8px 20px 6px 24px",
    // backgroundColor: "#FAFAFA!important",
  },
  userCardExpand: {
    margin: "40px 0px 40px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "8px 16px",
    // backgroundColor: "#FAFAFA!important",
    [theme.breakpoints.down("lg")]: {
      padding: "5px",
    },
  },
  userText: {
    textAlign: "center",
    marginLeft: "10px!important",
    marginTop: "10px!important",
    fontSize: "16px!important",
    lineHeight: "16px!important",
    color: `${palette.primary.light}!important`,
    maxWidth: "100px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px!important",
    },
  },
  list: {
    padding: "0",
    margin: "0",
    height: "100%",
    width: "100%",
    position: "relative"
  },
  prodcustName: {
    height: "24px",
    width: "24px",
    fontSize: '24px !important',
    color: '#11734A !important'
  },
  listItem: {
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    borderRadius: "0!important",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
    "& li": {
      listStyle: "none",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "14px",
      marginLeft: "12px",
      color: "var(--color-black)",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    "&:hover": {
      // backgroundColor: "#EBF0F2!important",
      cursor: "pointer",
    },
  },
  logoutItem: {
    position: 'absolute',
    bottom: '0px',
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#FAFAFA!important",
    borderRadius: "0!important",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
    "& li": {
      listStyle: "none",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "16px",
      marginLeft: "12px",
      color: palette.primary.light,
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    "&:last-child": {
      position: "absolute",
      bottom: "0px",
      marginBottom: "0px",
      zIndex: "1",
      width: "100%",
    },

    "&:hover": {
      color: "#EBF0F2!important",
      cursor: "pointer",
    },
  },
  blogItem: {
    position: 'absolute',
    bottom: '100px',
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#FAFAFA!important",
    borderRadius: "0!important",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
    "& li": {
      listStyle: "none",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "16px",
      marginLeft: "12px",
      color: '#B4B0B4',
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    "&:last-child": {
      position: "absolute",
      bottom: "10px",
      marginBottom: "10px",
      zIndex: "1",
      width: "100%",
    },

    "&:hover": {
      color: "#EBF0F2!important",
      cursor: "pointer",
    },
  },
  faqItem: {
    position: 'absolute',
    bottom: '60px',
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#FAFAFA!important",
    borderRadius: "0!important",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
    "& li": {
      listStyle: "none",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "16px",
      marginLeft: "12px",
      color: '#B4B0B4',
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    "&:last-child": {
      position: "absolute",
      bottom: "10px",
      marginBottom: "10px",
      zIndex: "1",
      width: "100%",
    },

    "&:hover": {
      color: "#EBF0F2!important",
      cursor: "pointer",
    },
  },
  sidebarBottomMenuItems: {
    position: 'absolute',
    bottom: 0,
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '4px',
    width: '100%',
  }
}));

interface SideBarModal {
  isExpandMode: boolean;
  setIsExpandMode?: Function;
}

const SideBar = (props: SideBarModal) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const pathName = useLocation();
  const { state: outcomeState, dispatch: userTypeDispatch } = useContext(UserTypeContext);
  const { state: IContactTypeState, dispatch: contactsDispatch } =
    useContext(ContactsContext);
    const { dispatch: trackingDispatch } =
    useContext(TrackingContext);
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategories = () => {
    categoryService
      .getCategories(outcomeState.token)
      .then((data: any) => {
        const newArry = data?.data?.map((ele, index) => {
          return {
            ...ele,
            arrayIndex: index,
          };
        });
        localStorage.setItem("categoryCount", `${newArry.length}`);
      })
      .catch((error) => {
        console.log("category list error", error);
      });
  };

  return (
    <RootStyle className="sidebar" style={props.isExpandMode ? { padding: '24px 0px 20px 5px', maxWidth: "300px" } : {}}>
      <div style={{
        width: '100%',
        display: 'flex',
        alignItems: "center",
        justifyContent: props.isExpandMode ? "center" : 'space-between',
        position: 'relative',
        gap: "16px",
        paddingRight: props.isExpandMode ? "0px": "24px"

      }}>
        {!props.isExpandMode && <img className={classes.prodcustName} alt="logo" src={require('./../assets/images/tnt-logo.png')}>

        </img>}
        <div
          style={{
            display: 'flex',
            padding: '2px',
            alignItems: 'center',
            gap: '10px',

          }}
        >
          <span
            style={{
              display: 'flex',
              padding: '2px',
              alignItems: 'center',
              justifyContent: "center",
              gap: '10px',
              borderRadius: '4px',
              background: 'transparent',
              width: "24px",
              height: "24px",
              border: "1px solid var(--color-svg)" 
            }}
            onClick={() => {
              props?.setIsExpandMode(!props?.isExpandMode)
              localStorage.setItem("isExpandMode", `${!props?.isExpandMode}`)
            }}
          >
            {props.isExpandMode ? <div style={{
              transform: 'scaleX(-1)'
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 16 16" fill="none">
                <path d="M11.0092 10.6666L8.34256 7.99998L11.0092 5.33331M7.00923 10.6666L4.34256 7.99998L7.00923 5.33331" stroke={"var(--color-svg)"} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 16 16" fill="none">
              <path d="M11.0092 10.6666L8.34256 7.99998L11.0092 5.33331M7.00923 10.6666L4.34256 7.99998L7.00923 5.33331" stroke={"var(--color-svg)"} strokeLinecap="round" strokeLinejoin="round" />
            </svg>}
          </span>
        </div>
      </div>

      {/* <Card
        elevation={0}
        className={
          props.isExpandMode ? classes.userCardExpand : classes.userCardNormal
        }
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/profile")}
      >

        <img
          alt="user"
          src={
            ImageFile
              ? outcomeState?.outcomePopup?.isUploaded
                ? URL.createObjectURL(outcomeState?.outcomePopup?.image)
                : `${baseEndpoints.getImage}${ImageFile}`
              : DummyUser
          }
          style={{
            height: 36,
            width: 36,
            borderRadius: "999px",
            marginRight: "12px",
            margin: props.isExpandMode ? "auto" : "",
            cursor: "pointer",
          }}
        />
        <input
          type="file"
          ref={hiddenFileInput}
          style={{ display: "none" }}
          onChange={(event) => {
            setImageFile(event.target.files[0]);
            setUploaded(true);
          }}
        />
        {!props.isExpandMode && (
          <Typography className={classes.userText}>
            {outcomeState?.outcomePopup?.first_name ? outcomeState?.outcomePopup?.first_name : userDetails?.first_name
              ? userDetails?.first_name
              : userValues?.first_name}{" "}
            {outcomeState?.outcomePopup?.last_name ? outcomeState?.outcomePopup?.last_name : userDetails?.last_name
              ? userDetails?.last_name
              : userValues?.last_name}
          </Typography>
        )}
      </Card> */}

      <ul className={classes.list}>
        {SideBarItems.filter(it => it).map((item) => (
          <Paper
            sx={{
              justifyContent: props.isExpandMode ? "center" : "flex-start",
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',


              padding: item.text === "Logout" ? '16px 0px 0px' : '16px 0px !important',
              paddingLeft: '0',
            }}
            elevation={0}
            className={
              item.text === "Logout" ? classes.logoutItem : item.text === "Blog" ? classes.blogItem : item.text === "FAQ" ? classes.faqItem : getActive(item.route, pathName?.pathname, item?.children)
                ? clsx(classes.listItem, "listItem active")
                : clsx(classes.listItem, "listItem")
            }
            key={item.text}
            onClick={() => {
              if (item.text === "Logout") {
                localStorage.removeItem("userDetails");
                localStorage.removeItem("userValues");
                localStorage.removeItem("userId");
                localStorage.removeItem("userDate");
                localStorage.removeItem("categoryCount");
                userTypeDispatch(addCategoryJourneyAction([]));
                userTypeDispatch(addNotesJourneyAction([]));
                userTypeDispatch(addOutcomeAction([]));
                userTypeDispatch(addInfluenceAction([]));
                userTypeDispatch(addRefelectiveAction([]));
                userTypeDispatch(addJourneyAction([] as any));
                userTypeDispatch(addNotesAction([] as any));
                userTypeDispatch(addCategoryExpandAction([] as any));
                userTypeDispatch(isPersonalMode(false))
                trackingDispatch(setTrackingState({
                  calendar: []
              }))
              }
              if (item.text === "Blog") {
                window.open("https://blog.tenacious-tracker.com", "_blank")
                return
              }
              if (item.text === "Social reports") {
                contactsDispatch(setShareModalOpen(true))
                return
              }
              navigate(item.route);
            }}
          >
            {item.icon()}
            {!props.isExpandMode && <li style={{
              zIndex: 1, fontWeight: 400,
              fontSize: '14px',
              
            }}>{item.text}</li>}
          </Paper>
        ))}
      </ul>
      <div className={classes.sidebarBottomMenuItems} style={{
        left: props.isExpandMode && '0px' 
      }}>
        {SidebarBottomItems.map(item => {
          return <SideBarItem /* className={classes.sideBarLogoutButton} */ style={{
            justifyContent: props.isExpandMode ? 'center' : 'flex-start'
          }} onClick={() => {
            if (item.text === "Logout") {
              localStorage.removeItem("userDetails");
              localStorage.removeItem("userValues");
              localStorage.removeItem("userId");
              localStorage.removeItem("userDate");
              localStorage.removeItem("categoryCount");
              userTypeDispatch(addCategoryJourneyAction([]));
              userTypeDispatch(addNotesJourneyAction([]));
              userTypeDispatch(addOutcomeAction([]));
              userTypeDispatch(addInfluenceAction([]));
              userTypeDispatch(addRefelectiveAction([]));
              userTypeDispatch(addJourneyAction([] as any));
              userTypeDispatch(addNotesAction([] as any));
              userTypeDispatch(addCategoryExpandAction([] as any));
              userTypeDispatch(isPersonalMode(false))
              trackingDispatch(setTrackingState({
                calendar: [],
                isLastAlreadyLoaded: false,
                showLoader: false
            }))
            }
            if (item.text === "Blog") {
              window.open("https://blog.tenacious-tracker.com", "_blank")
              return
            }
            navigate(item.route);
          }}>
            {item.icon}
            {/* <img src={Blog} alt="" className={classes.sidebarBottomIcon} /> */}
            {!props.isExpandMode && (
              <MenuItems variant="body2"  sx={{ ml: "8px", color: "var(--color-black)" }}>
                {item.text}
              </MenuItems>)}
          </SideBarItem>
        })}
      </div>
    </RootStyle >
  );
};

export default SideBar;
