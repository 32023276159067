/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";

// import palette from "../../theme/palette";
// import ProgressStepper from "./progressStepper";
import ModalCloseIcon from "../../../assets/images/icon/modalCloseIconBlack";
import { ContactsDataJson as contacts, ReviewData } from "./contactsData";
import AddContactModal from "./AddContactModal";
import ManageContactModal from "./ManageContactModal";
import { ContactsContext } from "../../../context/userIContacts";
import { contactsService } from "../../../services/contactsService";
import { UserTypeContext } from "../../../context/userStatus";
import SnackBarComp from "../../../components/snackBar";
import moment from "moment";
import { xVersion } from "../../../services/config";
import palette from "../../../theme/palette";
import SocialReportStepper from "./SocialReportStepper";
import { pxToRem } from "../../../theme/typography";
import {
  InfluenceGetList,
  OutcomeGetList,
} from "../../../interfaces/outcomeModal";
import SocialReportSchedules from "./SocialReportSchedules";
import { setSelectedRow, setSelectedRowDetails } from "../../../store/actions/reportTypeAction";
import { ReportsTypeContext } from "../../../context/userIReports";
// import { trackingService } from "../../services/tracking";
// import { UserTypeContext } from "../../context/userStatus";
// import { ButtonLoader } from "../../components/buttonLoader";
// import OutcomeclosePopup from "./OutcomeclosePopup";
// import DeleteIcon from "../../assets/images/icon/DeleteIcon";
// import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "var(--color-new-main)",
    borderRadius: "4px",
  },
}));
const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const useStyles = makeStyles((theme) => ({
  contactButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px",
    gap: "10px",

    height: "36px",

    /* BackgroundColors/PureWhite */

    background: "#FFFFFF",
    /* BackgroundColors/GhostButtonBorder */

    border: "1.5px solid rgba(17, 115, 74, 0.4)",
    borderRadius: "6px",
    marginBottom: "10px !important",
    margin: "10px 5px !important",
    color: "#3E4240 !important",
    fontWeight: 400,
    fontSize: "16px!important",
    lineHeight: "100%!important",
  },
  radioOptionDiv: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  radioOptionDivText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "#3E4240 !important",
  },
  raioOptionInputs: {
    height: "48px",

    /* BackgroundColors/GreenyAreaBG */

    /* BackgroundColors/Dividers */

    border: "1px solid #DCE6E0",
    borderRadius: "8px",
    padding: "0 4px",
    "& .MuiOutlinedInput-root": {
      margin: "auto",
      "& input": {
        padding: "2px !important",
      },
    },
  },
  raioOptionSelect: {
    height: "48px",

    /* BackgroundColors/GreenyAreaBG */

    /* BackgroundColors/Dividers */

    border: "1px solid #DCE6E0",
    borderRadius: "8px",
    padding: "0 4px",
    "& .MuiOutlinedInput-root": {
      margin: "auto 5px",
      display: "flex",
      alignItems: "center",
      "& .MuiInputBase-input": {
        padding: "2px !important",
      },
    },
  },
  reviewTitle: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "#3E4240",
    margin: "4px 0",
  },
  reviewDescription: {
    fontWeight: 400,
    fontSize: "16px",
    // lineHeight: '100%',
    /* identical to box height, or 16px */

    // display: 'flex',
    // alignItems: 'center',

    /* TextColors/DarkGrey */

    color: "#3E4240",
    margin: "4px 0",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    overflow: "hidden",
    lineClamp: 2,
    display: "-webkit-box",
    boxOrient: "vertical",
    // fontStyle: 'normal',
    // fontWeight: 500,
    // fontSize: '16px',
    // color: '#3E4240',
    // overflow: 'hidden',
    // display: '-webkit-box',
    // lineClamp: 2,
    // boxOrient: 'vertical',
  },
  treeClass: {
    "& .MuiTreeItem-root": {
      marginBottom: "32px",
    },
    "& .MuiTreeItem-content": {
      marginBottom: "24px",
      "& .MuiTreeItem-iconContainer": {
        marginRight: 0,
      },
      "& .MuiTreeItem-label": {
        paddingLeft: 0,
        "& .MuiTypography-root": {
          fontWeight: `400 !important`,
        },
      },
    },
    "& .MuiCollapse-wrapperInner": {
      "& .MuiTreeItem-content": {
        marginBottom: "16px",
        "& .MuiTreeItem-label": {
          "& .MuiTypography-root": {
            fontWeight: `400 !important`,
          },
        },
      },
    },
  },
  tabs: {
    "&.MuiTabs-root": {
      minHeight: "20px",
      "& .Mui-selected": {
        fontWeight: "700 !important",
      },
    },
    "& .MuiButtonBase-root": {
      padding: "8px 16px !important",
      fontSize: `${pxToRem(16)} !important`,
      minHeight: "20px",
      color: `${palette.primary.light} !important`,
      fontWeight: "400 !important",
    },
  },
}));
const bfsSearch = (graph, key, targetId) => {
  const queue = [...graph];

  while (queue.length > 0) {
    const currNode = queue.shift();
    if (currNode._id === targetId) {
      return currNode;
    }
    if (currNode[key]) {
      queue.push(...currNode[key]);
    }
  }
  return []; // Target node not found
};
interface ShareSocialReport {
  shareModalOpen: boolean;
  handleShareModalOpen: (val: boolean) => void;
  getAllContacts: () => void;
}

const ShareSocialReportModal = (props: ShareSocialReport) => {
  const classes = useStyles();
  const [sendStatus, setsendStatus] = useState("INSTANT");
  const [weekCount, setWeekCount] = useState(1);
  const [sendFrequency, setSendFrequence] = useState("WEEK");
  const [editRecord, setEditRecord] = useState(null);
  const [dayToSend, setdayToSend] = useState("MONDAY");
  const [sendTimeHour, setsendTimeHour] = useState("00");
  const [sendTimeMinute, setsendTimeMinute] = useState("00");
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [showSuccessMessage, setSuccessMessage] = useState(null);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [step, setStep] = useState(1);
  const [seelctedVal, setSelectedVal] = useState("LAST_SEVEN_DAYS");
  const [msgType, setMsgType] = useState("");
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const { state: outcomeState, dispatch: UserTypeDispatch } =
    useContext(UserTypeContext);
  const { dispatch: reportTypeDispatch } =
    useContext(ReportsTypeContext);
  const [visibleAddContactModal, setVisibleAddContactModal] = useState(false);
  const [visibleManageContactModal, setVisibleManageContactModal] =
    useState(false);
  const { state: IContactTypeState, dispatch: contactsDispatch } =
    useContext(ContactsContext);

  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedOutInflu, setSelectedOutInflu] = useState([]);
  const [viewSceduleReports, viewSocialReports] = useState(false);
  const allContacts = [
    {
      _id: "all",
      groupName: "All",
      contacts: [
        ...(IContactTypeState?.allContacts || []).map((it) => {
          if (it) {
            it.parent = "all";
          }
          return it;
        }),
      ],
    },
    ...IContactTypeState.groupContacts,
  ];
  const allDefaultStates = [
    {
      _id: "Outcomes",
      groupName: "Outcomes",
      items: [
        ...(outcomeState.outcomeList || []).map(
          (
            it: OutcomeGetList & {
              parent: string;
            }
          ) => {
            if (it) {
              it.parent = "Outcomes";
            }
            return it;
          }
        ),
      ],
    },
    {
      _id: "Influences",
      groupName: "Influences",
      items: [
        ...(outcomeState.influenceList || []).map(
          (
            it: InfluenceGetList & {
              parent: string;
            }
          ) => {
            if (it) {
              it.parent = "Influences";
            }
            return it;
          }
        ),
      ],
    },
  ];
  const allPersonalStates = [];
  if (
    outcomeState?.journeyCategoryList &&
    outcomeState?.journeyCategoryList.length
  ) {
    for (
      let index = 0;
      index < outcomeState?.journeyCategoryList.length;
      index++
    ) {
      const element = outcomeState?.journeyCategoryList[index];
      if (element.name !== "Other") {
        const alls = [...element.alls].filter(
          (it) => it.influenceId || it.outcomeId
        );
        allPersonalStates.push({
          _id: element.name,
          groupName: element.name,
          items: [
            ...(alls || []).map(
              (
                it: OutcomeGetList & {
                  parent: string;
                }
              ) => {
                if (it) {
                  it.parent = element.name;
                }
                return it;
              }
            ),
          ],
        });
      } else {
        allPersonalStates.push({
          _id: element.name,
          groupName: element.name,
          items: [
            ...(element.outcomes || []).map(
              (
                it: OutcomeGetList & {
                  parent: string;
                }
              ) => {
                if (it) {
                  it.parent = element.name;
                }
                return it;
              }
            ),
            ...(element.influences || []).map(
              (
                it: OutcomeGetList & {
                  parent: string;
                }
              ) => {
                if (it) {
                  it.parent = element.name;
                }
                return it;
              }
            ),
          ],
        });
      }
    }
  }

  function getAllIds(node, key, idList = []) {
    idList.push(node._id);
    if (node[key]) {
      node[key].forEach((child) => getAllIds(child, key, idList));
    }
    return idList;
  }
  // Get IDs of all children from specific node
  const getAllChild = (array, key, id) => {
    return getAllIds(bfsSearch(array, key, id), key);
  };

  // Get all father IDs from specific node
  const getAllFathers = (id, array, key, list = []) => {
    const node = bfsSearch(array, key, id);
    if (node.parent) {
      list.push(node.parent);

      return getAllFathers(node.parent, array, key, list);
    }

    return list;
  };

  function isAllChildrenChecked(selectedArray, array, key, node, list) {
    const allChild = getAllChild(array, key, node._id);
    const nodeIdIndex = allChild.indexOf(node._id);
    allChild.splice(nodeIdIndex, 1);

    return allChild.every((nodeId) =>
      selectedArray.concat(list).includes(nodeId)
    );
  }

  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation();
    const allChild = getAllChild(allContacts, "contacts", nodeId);
    const fathers = getAllFathers(nodeId, allContacts, "contacts");

    if (selectedNodes.includes(nodeId)) {
      // Need to de-check
      setSelectedNodes((prevSelectedNodes) =>
        prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id))
      );
    } else {
      // Need to check
      const ToBeChecked = allChild;
      for (let i = 0; i < fathers.length; ++i) {
        if (
          isAllChildrenChecked(
            selectedNodes,
            allContacts,
            "contacts",
            bfsSearch(allContacts, "contacts", fathers[i]),
            ToBeChecked
          )
        ) {
          ToBeChecked.push(fathers[i]);
        }
      }
      setSelectedNodes((prevSelectedNodes) =>
        [...prevSelectedNodes].concat(ToBeChecked)
      );
    }
  };
  const handleOutInfluSelect = (event, nodeId, isPersonal) => {
    event.stopPropagation();
    const allChild = getAllChild(
      isPersonal ? allPersonalStates : allDefaultStates,
      "items",
      nodeId
    );
    const fathers = getAllFathers(
      nodeId,
      isPersonal ? allPersonalStates : allDefaultStates,
      "items"
    );
    if (selectedOutInflu.includes(nodeId)) {
      // Need to de-check
      setSelectedOutInflu((prevSelectedNodes) =>
        prevSelectedNodes.filter((id) => !allChild.concat(fathers).includes(id))
      );
    } else {
      // Need to check
      const ToBeChecked = allChild;
      for (let i = 0; i < fathers.length; ++i) {
        if (
          isAllChildrenChecked(
            selectedOutInflu,
            isPersonal ? allPersonalStates : allDefaultStates,
            "items",
            bfsSearch(
              isPersonal ? allPersonalStates : allDefaultStates,
              "items",
              fathers[i]
            ),
            ToBeChecked
          )
        ) {
          ToBeChecked.push(fathers[i]);
        }
      }
      setSelectedOutInflu((prevSelectedNodes) =>
        [...prevSelectedNodes].concat(ToBeChecked)
      );
    }
  };
  const getContactsFromIds = () => {
    const contactsArr = [
      ...[...IContactTypeState?.allContacts].filter((it) =>
        selectedNodes.includes(it._id)
      ),
    ];
    for (
      let index = 0;
      index < IContactTypeState.groupContacts.length;
      index++
    ) {
      const element = IContactTypeState.groupContacts[index];
      if (element.contacts && element.contacts.length) {
        contactsArr.push(
          ...element.contacts.filter((it) => selectedNodes.includes(it._id))
        );
      }
    }
    return contactsArr;
  };
  const shareReport = () => {
    setShowLoadingBtn(true);
    if (
      allDefaultStates[0].items &&
      Array.isArray(allDefaultStates[0].items) &&
      allDefaultStates[0].items.length > 0
    ) {
      const objToSent = {
        // from: moment()
        //   .subtract(1, "week")
        //   .startOf("week")
        //   .add(1, "day")
        //   .format("MM-DD-YYYY"),
        // to: moment()
        //   .subtract(1, "week")
        //   .endOf("week")
        //   .add(1, "day")
        //   .format("MM-DD-YYYY"),
        contacts: getContactsFromIds(),
        baseUrl: window.location.origin + "/report-view",
        // weekNo: moment().subtract(1, "week").week(),
        outcomeIds: (allDefaultStates[0]?.items as any)
          ?.filter(
            (
              it: OutcomeGetList & {
                parent: string;
              }
            ) => selectedOutInflu.includes(it._id)
          )
          .map(
            (
              it: OutcomeGetList & {
                parent: string;
              }
            ) => it.outcomeId
          ),
        influenceIds: (allDefaultStates[1]?.items as any)
          .filter((it) => selectedOutInflu.includes(it._id))
          .map(
            (
              it: InfluenceGetList & {
                parent: string;
              }
            ) => it?.influenceId
          ),
        sendStatus: ["REGULARLY_MONTH", "REGULARLY_WEEK"].includes(sendStatus) ? "REGULARLY" : sendStatus,
        dayToSend: dayToSend,
        dateToSend: dayToSend,
        scheduleType: ["INSTANT"].includes(sendStatus) ? "LAST_WEEK" :  seelctedVal,
        timeToSend: moment()
          .set({
            hour: sendTimeHour ? Number(sendTimeHour || 0) : 0,
            minute: sendTimeMinute ? Number(sendTimeMinute || 0) : 0,
            second: 0,
          })
          .utc()
          .format(),
        shareReportId: null,
        // weekCount: weekCount,
      };
      if (sendStatus === "REGULARLY_MONTH") {
        objToSent.dateToSend = dayToSend
        delete objToSent.dayToSend
      }
      if (sendStatus === "REGULARLY_WEEK") {
        objToSent.dayToSend = dayToSend
        delete objToSent.dateToSend
      }
      if (["INSTANT"].includes(sendStatus)) {
        delete objToSent.dateToSend
        delete objToSent.dayToSend
      }
      if (editRecord && editRecord.shareReportId) {
        objToSent.shareReportId = editRecord.shareReportId;
      }
      contactsService
        .shareReport(objToSent, outcomeState.token)
        .then((data) => {
          setSuccessMessage(
            editRecord && editRecord.shareReportId
              ? sendStatus === "STOP"
                ? "Social report paused"
                : "Social report scheduled"
              : ["REGULARLY", "STOP"].includes(sendStatus)
                ? "Social report scheduled"
                : "Social report sent"
          );
          setEditRecord(null);
          navigator.clipboard.writeText(data.body.url);
          setShowSnakbar(true);
          setMsgType("success");
          setSnakbarMsg(data?.description);
        })
        .catch((err) => {
          if (err?.description) {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg(err?.description);
          }
        })
        .finally(() => {
          setShowLoadingBtn(false);
        });
    }
  };
  const handleAddContact = (values) => {
    setShowLoadingBtn(true);
    const foundObject = [...IContactTypeState?.allContacts].find(
      (it) => it.email === values.email
    );

    if (foundObject) {
      setShowLoadingBtn(false);
      setShowSnakbar(true);
      setMsgType("error");
      setSnakbarMsg("Contact is already added");
      return;
    }
    contactsService
      .createUpdateContact(values, outcomeState.token)
      .then((data) => {
        setVisibleAddContactModal(false);
        props.getAllContacts();
        setShowSnakbar(true);
        setMsgType("success");
        setSnakbarMsg(data?.description);
      })
      .catch((err) => {
        if (err?.description) {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(err?.description);
        }
      })
      .finally(() => {
        setShowLoadingBtn(false);
      });
  };

  useEffect(() => {
    if (editRecord && editRecord.shareReportId) {
      setSelectedNodes(editRecord.contacts.map((it) => it._id));
      const infrluOuArr = [];
      if (editRecord.influenceData && editRecord.influenceData.length > 0) {
        infrluOuArr.push(...editRecord.influenceData.map((it) => it._id));
      }
      if (editRecord.outcomeData && editRecord.outcomeData.length > 0) {
        infrluOuArr.push(...editRecord.outcomeData.map((it) => it._id));
      }
      setSelectedOutInflu(infrluOuArr);
      const isMonthly = ["LAST_THIRTY_DAYS", "LAST_MONTH"].includes(editRecord.scheduleType)
      const isWeekly = ["LAST_SEVEN_DAYS", "LAST_WEEK"].includes(editRecord.scheduleType)
      if (isMonthly) {
        setsendStatus("REGULARLY_MONTH")
        setSelectedVal(editRecord.scheduleType)
        setdayToSend(editRecord.dateToSend)
      } else if (isWeekly) {
        setsendStatus("REGULARLY_WEEK")
        setSelectedVal(editRecord.scheduleType)
        setdayToSend(editRecord.dayToSend)
      } else {
        setsendStatus(editRecord.sendStatus);
        setdayToSend(editRecord.dayToSend);
      }
      setWeekCount(editRecord.weekCount);
      setSendFrequence("WEEK");
      setsendTimeHour(moment(editRecord.timeToSend).format("h"));
      setsendTimeMinute(moment(editRecord.timeToSend).format("mm"));
      //     const [selectedNodes, setSelectedNodes] = useState([]);
      // const [selectedOutInflu, setSelectedOutInflu] = useState([]);
      // const [viewSceduleReports, viewSocialReports] = useState(false);
    } else {
      setSelectedNodes([]);
      setSelectedOutInflu([]);
      setsendStatus("INSTANT");
      setWeekCount(1);
      setSendFrequence("WEEK");
      setdayToSend("MONDAY");
      setsendTimeHour("00");
      setsendTimeMinute("00");
    }
  }, [editRecord]);

  return (
    <Grid>
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={() => {
          setShowSnakbar(false);
        }}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "success"}
      />
      <Dialog
        onClose={() => props.handleShareModalOpen(false)}
        open={props.shareModalOpen}
        className="dialogWrapper dialogWrapper-share dialogWrapper-mobileScreen"
        sx={{
          ".MuiPaper-root": {
            height: "600px",
            minWidth: "723px",
            width: "723px",
            padding: "48px 70px 20px !important",
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: palette.primary.light,
                fontSize: `${pxToRem(26)} !important`,
                lineHeight: "100% !important",
                // textAlign: "center !important",
                fontWeight: `700 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(26)} !important`,
                    lineHeight: "100% !important",
                  },
                },
              }}
            >
              {viewSceduleReports
                ? "Scheduled social reports"
                : "Share your social report"}
            </Typography>

            {(step === 1 && !viewSceduleReports) || showSuccessMessage ? (
              <Box
                onClick={() => {
                  viewSocialReports(!viewSceduleReports);
                  setSuccessMessage(null);
                  setEditRecord(null);
                }}
              >
                {editRecord && editRecord.shareReportId ? (
                  <Typography
                    sx={{
                      color: palette.secondary.contrast,
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "120% !important",
                      cursor: "pointer",
                      // textAlign: "center !important",
                      fontWeight: `400 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                  >
                    Go back
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: palette.common.black,
                      fontSize: `${pxToRem(14)} !important`,
                      lineHeight: "120% !important",
                      textDecoration: "underline",
                      cursor: "pointer",
                      // textAlign: "center !important",
                      fontWeight: `400 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(14)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                  >
                    View scheduled reports
                  </Typography>
                )}
              </Box>
            ) : (
              !viewSceduleReports && (
                <Box
                  onClick={() => {
                    if (viewSceduleReports) {
                      viewSocialReports(!viewSceduleReports);
                    } else {
                      setStep((prevStep) => prevStep - 1);
                    }
                  }}
                >
                  <Typography
                    sx={{
                      color: palette.secondary.contrast,
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "120% !important",
                      cursor: "pointer",
                      // textAlign: "center !important",
                      fontWeight: `400 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                  >
                    Go back
                  </Typography>
                </Box>
              )
            )}
          </Box>

          <IconButton
            sx={{ position: "absolute !important", top: 0, right: 0 }}
            // onClick={handleCloseStartModal}
            onClick={() => {
              props.handleShareModalOpen(false)
              reportTypeDispatch(setSelectedRow({}));
              reportTypeDispatch(setSelectedRowDetails({}));
            }}
          >
            <ModalCloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className="hide-scrollbar"
          sx={{
            overflowX: "hidden",
          }}
        >
          {!viewSceduleReports && (
            <SocialReportStepper
              allContacts={allContacts}
              allDefaultStates={allDefaultStates}
              classes={classes}
              selectedNodes={selectedNodes}
              handleNodeSelect={handleNodeSelect}
              setVisibleAddContactModal={setVisibleAddContactModal}
              radioValue={sendStatus}
              setRadioValue={setsendStatus}
              shareReport={shareReport}
              seelctedVal={seelctedVal} setSelectedVal={setSelectedVal}
              setVisibleManageContactModal={setVisibleManageContactModal}
              step={step}
              setStep={setStep}
              handleOutInfluSelect={handleOutInfluSelect}
              selectedOutInflu={selectedOutInflu}
              allPersonalStates={allPersonalStates}
              outcomeState={outcomeState}
              weekCount={weekCount}
              setWeekCount={setWeekCount}
              dayToSend={dayToSend}
              setdayToSend={setdayToSend}
              sendTimeHour={sendTimeHour}
              setsendTimeHour={setsendTimeHour}
              sendTimeMinute={sendTimeMinute}
              sendFrequency={sendFrequency}
              setSendFrequence={setSendFrequence}
              setsendTimeMinute={setsendTimeMinute}
              showSuccessMessage={showSuccessMessage}
              setSuccessMessage={setSuccessMessage}
              editRecord={editRecord}
            />
          )}
          {viewSceduleReports && (
            <SocialReportSchedules
              socialReportsScedules={[]}
              allContacts={allContacts}
              allDefaultStates={allDefaultStates}
              viewSceduleReports={viewSceduleReports}
              viewSocialReports={viewSocialReports}
              classes={classes}
              editRecord={editRecord}
              setEditRecord={setEditRecord}
            />
          )}
        </DialogContent>
        {visibleAddContactModal && (
          <AddContactModal
            visibleAddContactModal={visibleAddContactModal}
            setVisibleAddContactModal={setVisibleAddContactModal}
            handleAddContact={handleAddContact}
            showLoadingBtn={showLoadingBtn}
            setShowLoadingBtn={setShowLoadingBtn}
          />
        )}
        {visibleManageContactModal && (
          <ManageContactModal
            visibleManageContactModal={visibleManageContactModal}
            setVisibleManageContactModal={setVisibleManageContactModal}
            showLoadingBtn={showLoadingBtn}
            setShowLoadingBtn={setShowLoadingBtn}
            setShowSnakbar={setShowSnakbar}
            setMsgType={setMsgType}
            setSnakbarMsg={setSnakbarMsg}
            getAllContacts={props.getAllContacts}
          />
        )}
      </Dialog>
    </Grid>
  );
};
export default ShareSocialReportModal;
